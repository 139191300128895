import React from 'react';
import { Input, InputGroup } from '@chakra-ui/react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { StandardPhoneNumberInputProps } from './StandardPhoneNumberInput.types';

const StandardPhoneNumberInput = ({ fieldConfiguration }: StandardPhoneNumberInputProps) => {
    return (
        <PhoneInput
            value={fieldConfiguration.value}
            placeholder={fieldConfiguration.placeholderText}
            defaultCountry={fieldConfiguration.value?.country}
            onChange={(value) => fieldConfiguration?.onChange ? fieldConfiguration.onChange(value) : undefined}
            disabled={fieldConfiguration.isLoading || fieldConfiguration.optionalConfiguration?.isDisabled}
            inputComponent={Input}
            containerComponent={InputGroup}
            international
            data-test-id={fieldConfiguration.dataTestId}
        />
    );
};
export default StandardPhoneNumberInput;
