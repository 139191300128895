import { connect } from 'react-redux';
import { ComponentType } from 'react';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { Dispatch } from '@manigo/manigo-commons';

import { clearLoginState, initialiseLogin } from 'store/authorisation/actions';
import { unauthorisedDeclineConsentRequest } from 'store/new-consent/actions';
import { RootState } from 'store/rootReducer';
import { authorisationReducerName } from 'store/authorisation/reducer';

import { newConsentReducerName } from 'store/new-consent/reducer';
import LoginPage from './LoginPage.layout';
import { LoginPageDispatchProps, LoginPageStateProps } from './LoginPage.types';


const mapStateToProps = (state: RootState): LoginPageStateProps => ({
    loginFormErrorMessage: state[authorisationReducerName].loginFormErrorMessage,
    isLoadingLoginForm: state[authorisationReducerName].isLoadingLoginForm,
    loginBlockUntil: state[authorisationReducerName].loginBlockUntil,
    loginPermanentBlock: state[authorisationReducerName].loginPermanentBlock,
    submittedLoginField: state[authorisationReducerName].submittedLoginField,
    newConsentUuid: state[newConsentReducerName].newConsentUuid,
    isLoadingUnauthorisedDeclineConsentRequest: state[newConsentReducerName].isLoadingUnauthorisedDeclineConsentRequest,
});

const mapDispatchToProps = (dispatch: Dispatch): LoginPageDispatchProps => ({
    dispatchInitialiseLogin: (formData) => dispatch(initialiseLogin(formData)),
    dispatchClearLoginState: () => dispatch(clearLoginState()),
    dispatchUnauthorisedDeclineConsentRequest: (payload) => dispatch(unauthorisedDeclineConsentRequest(payload)),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LoginPage);
