import React from 'react';

import { Button } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import { MdLightMode, MdDarkMode } from 'react-icons/md';
import { ColorModeTogglerProps } from './ColorModeToggler.types';
import TheIcon from '../TheIcon';


function ColorModeToggler({ title = 'Toggle color mode' }: ColorModeTogglerProps): JSX.Element {
    const { toggleColorMode } = useColorMode();
    const { colorMode } = useColorMode();
    return (
        <Button
            type="button"
            data-test-id={'standard-link'}
            onClick={toggleColorMode}
            title={title}
            aria-label={title}
        >
            <TheIcon Icon={colorMode === 'light' ? MdDarkMode : MdLightMode} />
        </Button>
    );
}

export default ColorModeToggler;
