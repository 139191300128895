import {
    loginInitAPI,
    loginConfirmAPI,
    extendTokenValidityAPI,
    expireTokenAPI, getTokensForDuplicatedSessionAPI,
} from './index';

class AuthorisationService {

    public loginInit(rawRequestPayload) {
        return loginInitAPI(rawRequestPayload);
    }

    public loginConfirm(payload) {
        const requestPayload = { ...payload };
        return loginConfirmAPI(requestPayload);
    }

    public extendTokenValidity(refreshToken): Promise<any> {
        return extendTokenValidityAPI({ refreshToken });
    }

    public getTokensForDuplicatedSession(requestPayload) {
        return getTokensForDuplicatedSessionAPI(requestPayload);
    }

    public expireToken() {
        return expireTokenAPI();
    }
}

export default AuthorisationService;

