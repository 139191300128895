import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const AccountFormItem = styled.div`
    display: block;
    margin-bottom: 1.5rem;
    min-width: fit-content;
    
    .chakra-form-control {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: minmax(0,1fr) auto;
        grid-column-gap: 1ch;
        align-content: center;
        
        .chakra-form__label {
            text-align: end;
            padding: 0;
            margin: 0; 
            cursor: pointer;
            font-size: var(--chakra-fontSizes-sm);
            color: var(--chakra-colors-BWP_colorSchemeKey-500);
        }
        .chakra-form__helper-text { display: none; }
    }
`;


export const BottomRow = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-column-gap: 1rem;
    align-items: center !important;
`;

export const AlignedAttributes = styled.table`
    table-layout: auto;
    width: min-content;
    border-collapse: collapse;
`;

export const AttributeName = styled.td`
    padding-right: 0.5rem;
    padding-bottom: 1rem;
    font-size: var(--chakra-fontSizes-xs);

    @media (min-width: 500px) {
        padding-right: 0.75rem;
        font-size: var(--chakra-fontSizes-sm);
    }
    
    @media (min-width: 768px) {
        padding-right: 1rem;
        font-size: var(--chakra-fontSizes-sm);
    }
`;

export const AttributeValue = styled.td`
    font-size: var(--chakra-fontSizes-xs);
    padding-bottom: 1rem;
    font-weight: 600;

    @media (min-width: 500px) {
        padding-right: 0.75rem;
        font-size: var(--chakra-fontSizes-sm);
    }
    
    @media (min-width: 768px) {
        font-weight: 700;
        font-size: var(--chakra-fontSizes-lg);
    }
`;

const CurrencyDecoration = styled.div<{ toLeft?: boolean }>`  
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  img {
    top: 0;
    bottom: 0;
    ${(props) => (!props.toLeft ? css('right: -50%;') : css('left: -50%;'))}
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
`;


export default {
    AccountFormItem,
    BottomRow,
    AlignedAttributes,
    AttributeName,
    AttributeValue,
    CurrencyDecoration,
};
