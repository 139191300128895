import styled from 'styled-components';

const CardWrapper = styled.div`
  outline: none;
  width: 100%;
  max-width: 100%;
  
  .chakra-card__footer {
    display: block;
  }
`;


const CardFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, auto);
  grid-column-gap: 50px;
  align-items: center;
`;

const CardFooterActionsWrapper = styled.div`
  display: flex;
  column-gap: 50px;
  justify-content: right;
  align-items: center;
`;


const CardFooterBadgesWrapper = styled.div`
  display: flex;
  column-gap: 50px;
  justify-content: left;
  align-items: flex-start;
`;

const CardBodyContentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

const CardBodyFiledWrapper = styled.div`
  width: 100%;

  
  @media (max-width: 768px) {
    max-width: 100% !important;
  }
`;


export default { CardWrapper, CardFooterWrapper, CardFooterBadgesWrapper, CardFooterActionsWrapper, CardBodyContentWrapper, CardBodyFiledWrapper };
