import { createAction } from '@reduxjs/toolkit';
import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';

import {
    BLOCK_NAVIGATION,
    HIDE_NAVIGATION_CONFIRMATION,
    NAVIGATION_BACK_CANCELED,
    NAVIGATION_CANCELED,
    NAVIGATION_STOPPED,
    PREFORM_NAVIGATION,
    PREFORM_NAVIGATION_BACK,
    PREFORM_NAVIGATION_BACK_SUCCESS,
    PREFORM_NAVIGATION_SUCCESS,
    REQUEST_NAVIGATION,
    REQUEST_NAVIGATION_BACK,
    SET_SEARCH_PARAM,
    SHOW_NAVIGATION_CONFIRMATION,
    UNBLOCK_NAVIGATION,
} from './actions.types';


export const requestNavigation = createAction(REQUEST_NAVIGATION, ({ locationPathname, meta }: LocationChangeRequest) => {
    return {
        payload: locationPathname,
        meta,
    };
});


export const blockNavigation = createAction(BLOCK_NAVIGATION, ({ onStop, reason }) => {
    return { payload: onStop, meta: reason };
});

export const unblockNavigation = createAction(UNBLOCK_NAVIGATION);


export const navigationStopped = createAction(NAVIGATION_STOPPED, ({ locationPathname, meta }: LocationChangeRequest) => {
    return {
        payload: locationPathname,
        meta,
    };
});


export const showNavigationConfirmation = createAction(SHOW_NAVIGATION_CONFIRMATION);

export const hideNavigationConfirmation = createAction(HIDE_NAVIGATION_CONFIRMATION);


export const navigationCanceled = createAction(NAVIGATION_CANCELED);


export const preformNavigation = createAction(PREFORM_NAVIGATION, ({ locationPathname, meta }: LocationChangeRequest) => {
    return {
        payload: locationPathname,
        meta,
    };
});


export const preformNavigationSuccess = createAction(PREFORM_NAVIGATION_SUCCESS, (enhancedLocation: EnhancedLocation) => {
    return { payload: enhancedLocation };
});


export const requestNavigationBack = createAction(REQUEST_NAVIGATION_BACK);


export const navigationBackCanceled = createAction(NAVIGATION_BACK_CANCELED);


export const preformNavigationBack = createAction(PREFORM_NAVIGATION_BACK);


export const preformNavigationBackSuccess = createAction(PREFORM_NAVIGATION_BACK_SUCCESS, (enhancedLocation: EnhancedLocation) => {
    return { payload: enhancedLocation };
});
export const setSearchParam = createAction(SET_SEARCH_PARAM, (searchParam:string) => ({ payload: searchParam }));

export default {
    requestNavigation,
    blockNavigation,
    unblockNavigation,
    navigationCanceled,
    preformNavigation,
    preformNavigationSuccess,
    requestNavigationBack,
    navigationBackCanceled,
    preformNavigationBack,
    preformNavigationBackSuccess,
    setSearchParam,
};
