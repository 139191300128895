import React, { JSX } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { timeToAutoLogoutCountDownBanner } from 'config/config';
import AppBlurOverlay from 'components/common/atoms/AppBlurOverlay';

import Styled from './AppIdleTimer.styled';
import { AppIdleTimerProps } from './AppIdleTimer.types';
import AutoLogoutBanner from './AutoLogoutBanner';


function AppIdleTimer({
    children,
    isAutoLogoutBannerVisible,
    dispatchShowAutoLogoutBanner,
    dispatchUserActivity,
}: AppIdleTimerProps) : JSX.Element {
    const handleOnIdle = () => dispatchShowAutoLogoutBanner();
    const handleOnActive = () => dispatchUserActivity();

    // XXX
    useIdleTimer({
        timeout: timeToAutoLogoutCountDownBanner,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        crossTab: true,
    });
    return (
        <Styled.AppWrapper $enableOverflowHidden={isAutoLogoutBannerVisible}>
            {isAutoLogoutBannerVisible && <AutoLogoutBanner />}
            <AppBlurOverlay activeBlur={isAutoLogoutBannerVisible}>
                {children}
            </AppBlurOverlay>
        </Styled.AppWrapper>
    );
}


export default AppIdleTimer;
