import { ComponentSingleStyleConfig, defineStyleConfig } from '@chakra-ui/react';

const ButtonStyle:ComponentSingleStyleConfig = defineStyleConfig({
    variants: {
        danger: {
            bg: 'error',
            color: 'white',
            _hover: {
                bg: 'error_hover',
                _disabled: { bg: 'error_disabled' },
            },
            _dark: {
                bg: 'error_dark',
                color: 'white',
                _hover: {
                    bg: 'error_dark_hover',
                    _disabled: {
                        bg: 'error_dark_disabled',
                        _hover: { bg: 'error_dark_disabled' },
                    },
                },
            },
        },
    },
});

export default ButtonStyle;
