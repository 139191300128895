import styled from '@emotion/styled';

const UserProfileItem = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  column-gap: 10px;
`;

export default { UserProfileItem };
