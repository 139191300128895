import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { switchAnatomy } from '@chakra-ui/anatomy';

import { BASE_COLOR_DARK_NUMBER, BASE_COLOR_NUMBER, getChakraColor } from 'utils/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
    thumb: {
        bg: 'white',
        _dark: { bg: 'white' },
    },
    track: {
        bg: 'gray.200',
        borderRadius: 'full',
        _checked: { bgColor: getChakraColor(BASE_COLOR_NUMBER) },
        _dark: {
            bg: 'gray.400',
            _checked: { bgColor: getChakraColor(BASE_COLOR_DARK_NUMBER) },
        },
    },
});
const switchTheme = defineMultiStyleConfig({ baseStyle });
export default switchTheme;
