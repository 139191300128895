import React from 'react';
import { MdCheck } from 'react-icons/md';
import { Box } from '@chakra-ui/react';

import TheIcon from 'components/common/atoms/TheIcon';
import TheSpinner from 'components/common/atoms/TheSpinner';


import Styled from './AppLoadingPlaceholder.styled';


export const renderLoadingInfoItem = (item, itemLabel) => {
    const isLoaded = item?.length > 0 || item?.id > 0;
    return (
        <Styled.LoadingProgressInfo $isLoaded={isLoaded}>
            <Styled.Label>{itemLabel}</Styled.Label>
            <Styled.LoadedLabelWrapper>
                {isLoaded
                    ? <TheIcon Icon={MdCheck} />
                    : <TheSpinner thickness="2px" />}

            </Styled.LoadedLabelWrapper>

        </Styled.LoadingProgressInfo>
    );
};

export const renderLoadingDetails = ({
    t,
    currencies,
    countries,
    accessControl,
    isRefreshingSession,
}) => accessControl.isAuthorised && (
    <Styled.LoadingProgressInfoWrapper>
        <Box>
            {isRefreshingSession ? renderLoadingInfoItem(isRefreshingSession ? [] : Array.from({ length: 1 }), t('common:loading.appRefreshItems.item1')) : (
                <>
                    {renderLoadingInfoItem(currencies, t('common:loading.appInitItems.item1'))}
                    {renderLoadingInfoItem(countries, t('common:loading.appInitItems.item2'))}
                </>
            )}
        </Box>
    </Styled.LoadingProgressInfoWrapper>
);
