import { BehaviorSubject, EMPTY, merge, Observable, Subject } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Action, isDeepEqual } from '@manigo/manigo-commons';

import { createLocation$, createStop$ } from 'services/location/LocationService.helpers';


class LocationService {
    history; // XXX shared/common (the only one allowed to be used) history object created form window object at the very beginning of app. init.

    location; // form window.location

    readonly location$: Observable<any>; // observable form history.listen()

    readonly lock$: Subject<Action[] | any> = new BehaviorSubject(undefined);

    readonly stop$: Observable<any>; // eventually observable form history.block()


    constructor(history, location) {
        this.history = history;
        this.location = location;

        this.location$ = createLocation$(history).pipe();


        this.stop$ = this.lock$.pipe(
            distinctUntilChanged(isDeepEqual), // less noise
            switchMap((actions) => (actions
                ? createStop$(history, actions)
                : EMPTY)),
        );
    }


    // XXX uber important - affects middleware dependencies behaviour,
    // see 'createStore(dependencies)' and services filtering by `filter(isObservable)`
    public get action$() {
        return merge(this.location$, this.stop$);
    }

    public lock(onStop) {
        this.lock$.next(onStop);
    }

    unlock(value?: any) {
        this.lock$.next(value);
    }

    public goBack() {
        this.history.back();
    }

    goForward() {
        this.history.forward();
    }


    refresh() {
        const { pathname, state } = this.location;
        this.history.replace(pathname, state);
    }

    // XXX forward push/replace case - actual history modification
    public navigate(path: string, meta): void {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const push = (path, meta) => this.history.push(path, meta?.state);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const replace = (path, meta) => this.history.replace(path, meta?.state);
        const action = meta?.replace ? replace : push;
        action(path, meta);
    }

    // XXX back case - actual history modification
}

export default LocationService;
