import { extendTheme, ThemeOverride, withDefaultColorScheme } from '@chakra-ui/react';

import { primaryColorSchemeKey, secondaryColorSchemeKey } from 'config/config';
import { baseThemeConfig, breakpoints, styles } from './styles';
import {
    Modal,
    Input,
    CheckBox,
    Switch,
    Radio,
    Button,
} from './components';
import { WL_CONFIG } from '../environment';

const getOverrides = (): ThemeOverride => {
    if (!WL_CONFIG.themeVariables) return {};
    const { monoFontFamily, fontFamilyHeading, borderRadius } = WL_CONFIG.themeVariables as any;
    return {
        config: baseThemeConfig,
        styles,
        breakpoints,
        semanticTokens: {
            colors: {
                error: 'red.500',
                error_hover: 'red.600',
                error_disabled: 'red.200',

                error_dark: 'red.300',
                error_dark_hover: 'red.400',
                error_dark_disabled: 'red.200',
            },
        },
        fonts: {
            heading: fontFamilyHeading,
            mono: monoFontFamily,
        },
        components: {
            Modal,
            Input,
            CheckBox,
            Switch,
            Radio,
            Button,
            // PinInputField is not supported for themin chakra-ui, so we need to add it manually
            PinInputField: Input,
        },
        radii: { md: borderRadius },
    };
};
const getExtensions = () => {
    if (!WL_CONFIG.themeVariables) return {};

    const {
        primary50,
        primary100,
        primary200,
        primary300,
        primary400,
        primary500,
        primary600,
        primary700,
        primary800,
        primary900,

        secondary50,
        secondary100,
        secondary200,
        secondary300,
        secondary400,
        secondary500,
        secondary600,
        secondary700,
        secondary800,
        secondary900,
    } = WL_CONFIG.themeVariables as any;

    return {
        colors: {
            [primaryColorSchemeKey]: {
                50: primary50,
                100: primary100,
                200: primary200,
                300: primary300,
                400: primary400,
                500: primary500,
                600: primary600,
                700: primary700,
                800: primary800,
                900: primary900,
            },
            [secondaryColorSchemeKey]: {
                50: secondary50,
                100: secondary100,
                200: secondary200,
                300: secondary300,
                400: secondary400,
                500: secondary500,
                600: secondary600,
                700: secondary700,
                800: secondary800,
                900: secondary900,
            },
        },
    };
};

export function createTheme({ configuration }) {
    return extendTheme(
        getExtensions(),
        getOverrides(),
        withDefaultColorScheme({ colorScheme: configuration.colorScheme }),
    );
}

export default createTheme;
