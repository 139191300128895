import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import { currentUserReducerName } from 'store/current-user/reducer';

import StandardValue from './StandardValue.layout';
import { StandardValueOwnProps, StandardValueStateProps } from './StandardValue.types';

const mapStateToProps = (state: RootState): StandardValueStateProps => ({ userPreferences: state[currentUserReducerName].userPreferences });

const decorate = compose<ComponentType<StandardValueOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps, {}),
);

export default decorate(StandardValue);
