import React, { useMemo, JSX } from 'react';
import { RouteConfiguration } from '@manigo/manigo-commons';

import { PN } from 'models/domain/permissions';
import { consentsListRootRoutePath } from 'config/routes';
import AuthorisedLayout from 'components/App/layouts/AuthorisedLayout';
// authorised pages
import ConsentsListPage from 'components/pages/authorised/ConsentsListPage';

import { hasAccessRights, renderRoutes } from '../ApplicationContent.helpers';
import { AnimatedRoutes } from '../AnimatedRoutes/AnimatedRoutes.layout';


function createAuthorisedRoutesConfiguration(): RouteConfiguration[] {
    return [
        {
            path: consentsListRootRoutePath,
            element: <ConsentsListPage />,
            hasPermission: PN.OPEN_BANKING.GET_USER_CONSENTS,
        },
    ];
}

function AuthorisedVerified({ accessControl, enhancedCurrentLocation }): JSX.Element {
    const authorisedRoutesConfiguration = createAuthorisedRoutesConfiguration();

    const defaultRedirectionPath = consentsListRootRoutePath;

    const getAvailableAuthorisedRoutes = (routesConfiguration) => {
        const availableRoutes = routesConfiguration.filter((routeConfiguration) => hasAccessRights({
            routeConfiguration,
            accessControl,
        }));

        return renderRoutes({ routesConfiguration: availableRoutes });
    };

    const authorisedRoutes = useMemo(
        () => getAvailableAuthorisedRoutes(authorisedRoutesConfiguration),
        [authorisedRoutesConfiguration],
    );
    //my profile dropdown yap
    return (
        <AuthorisedLayout>
            <AnimatedRoutes
                routesConfiguration={authorisedRoutes}
                defaultRedirectionPath={defaultRedirectionPath}
                location={enhancedCurrentLocation}
            />
        </AuthorisedLayout>
    );

}


export default AuthorisedVerified;

