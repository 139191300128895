import React, { useEffect, JSX, useMemo } from 'react';
import { Box, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { MdSend } from 'react-icons/md';
import { loginRootRoutePath } from 'config/routes';
import useCreateDigitsCodeInputReferences from 'hook/useCreateDigitsCodeInputReferences';

import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';
import DigitsCodeInput from 'components/common/molecules/DigitsCodeInput';

import Button from 'components/common/atoms/Button';
import InfoIconTooltip from 'components/common/molecules/InfoIconTooltip';
import { renderFormError } from 'components/common/organisms/StandardForm/StandardForm.layout';

import getLoginConfirmFormError from './PasscodeConfirmPage.helpers';
import { PasscodeConfirmPageProps } from './PasscodeConfirmPage.types';

function PasscodeConfirmPage({
    loginConfirmationValues,
    loginRequestId,
    loginToken,
    loginConfirmFormError,
    isLoadingLoginConfirmationForm,
    dispatchConfirmLogin,
    dispatchRequestNavigation,
    t,
}: PasscodeConfirmPageProps): JSX.Element {
    const loginConfirmFormErrorTranslated = useMemo(() =>
        getLoginConfirmFormError({ loginConfirmFormError, t }), [loginConfirmFormError]);

    useEffect(() => {
        if (!loginRequestId || !loginToken) {
            dispatchRequestNavigation({ locationPathname: loginRootRoutePath });
        }
    }, []);

    const { handleSubmit } = useForm<any>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });
    const parseValues = () => Object.values(loginConfirmationValues).join('');

    const onSubmit = () => {
        dispatchConfirmLogin({
            passcode: parseValues(),
            token: loginToken,
        });
    };

    const references = useCreateDigitsCodeInputReferences();
    return (
        <StandardUnauthorisedPage
            pageTitle={t('unauthorised:screenTitles.passcodeConfirmation')}
        >
            <div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    id={'passcode-confirmation-form'}
                    noValidate
                >
                    <VStack
                        spacing={5}
                        align="stretch"
                    >
                        <Box>
                            <FormLabel display={'flex'} whiteSpace={'nowrap'} alignItems={'center'} gap={1}>
                                {t('formsElements.passcode.text')}
                                <InfoIconTooltip infoTooltipText={t('formsElements.passcode.infoTooltipText')} />
                            </FormLabel>
                            <DigitsCodeInput references={references} />
                        </Box>

                        {renderFormError(loginConfirmFormErrorTranslated)}

                        <HStack justify={'flex-end'}>
                            <Button
                                icon={MdSend}
                                type={'submit'}
                                ref={references.refButtonSubmit}
                                id={'passcode-submit-button'}
                                dataTestId={'passcode-submit-button'}
                                label={t('unauthorised:formsElements.login.text')}
                                isDisabled={parseValues()?.length < 6 || loginConfirmFormError}
                                isLoading={isLoadingLoginConfirmationForm}
                                loadingText={t('unauthorised:formsElements.login.loadingText')}
                                marginLeft={'auto'}
                            />
                        </HStack>
                    </VStack>
                </form>
            </div>
        </StandardUnauthorisedPage>
    );
}


export default PasscodeConfirmPage;
