import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';
import { css } from '@emotion/react';

import ImageRoot from 'components/common/atoms/Image';
import { getNavbarInnerWrapperCss } from '../../layouts.helpers';

const UnauthorisedHeadingWrapper = styled.div<{ $isDesktop?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: var(--chakra-space-4);
  ${props => props.$isDesktop && css`
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  `}
  ${() => getNavbarInnerWrapperCss()};
`;

const UnauthorisedLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  align-items: center;

  h2, h3 {
    margin: 0;
    font-family: monospace;
    font-size: 12px;
    text-transform: uppercase;
    word-spacing: 1px;

      @media (min-width: 768px) {
          font-size: 17px;
          word-spacing: 3px;
      }
  }
`;

const ActionButtons = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 20px;
  justify-items:  flex-end;
  justify-content: flex-end;
`;
const UnauthorisedLogo = styled(ImageRoot)`
`;

export default { UnauthorisedHeadingWrapper, UnauthorisedLogoWrapper, UnauthorisedLogo, ActionButtons };
