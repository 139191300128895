import React from 'react';
import { ConsentListItem } from '@manigo/manigo-domain-typings';

import { StandardValueType } from 'models/app/standardValues';
import { humanFriendlyPermissionName } from 'utils/string-tools';
import StandardValue from 'components/common/molecules/StandardValue';

import Styled from './Permissions.styled';

function ConsentPermissionsRenderer({ permissions }: {
    permissions: ConsentListItem['permissions']
    }) {
    return (
        <Styled.PermissionsContainer>
            <Styled.PermissionsList>
                {permissions.map((permission) =>
                    <li key={permission}><StandardValue value={humanFriendlyPermissionName(permission)} valueType={StandardValueType.TEXT} /></li>)}
            </Styled.PermissionsList>
        </Styled.PermissionsContainer>
    );
}

export default ConsentPermissionsRenderer;
