import styled from 'styled-components';
import { Breadcrumb, chakra } from '@chakra-ui/react';


const StandardAuthorisedPageWrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
`;
const StandardAuthorisedPageInnerWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
`;

const BreadCrumbContainer = chakra(Breadcrumb, {
    baseStyle: {
        fontWeight: 'medium',
        fontSize: 'sm',
        spacing: '8px',
    },
});

export default { StandardAuthorisedPageWrapper, StandardAuthorisedPageInnerWrapper, BreadCrumbContainer };
