import { NumericId, Uuid } from '@manigo/manigo-domain-typings';
import { ConfirmLoginResponsePayload, LoginType } from 'models/domain/authorisation';

export interface InitialiseLoginPayload {
    login: string;
    password: string;
    loginType: LoginType;
}

export interface InitialiseLoginSuccessPayload {
    id: NumericId,
    sessionUuid: Uuid,
    token: string,
}

export interface ConfirmLoginPayload {
    passcode: string,
    token: string,
}

export type ConfirmLoginSuccessPayload = ConfirmLoginResponsePayload;

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const INITIALISE_LOGIN = 'INITIALISE_LOGIN';
export const INITIALISE_LOGIN_SUCCESS = 'INITIALISE_LOGIN_SUCCESS';
export const INITIALISE_LOGIN_FAILURE = 'INITIALISE_LOGIN_FAILURE';

export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const CONFIRM_LOGIN_SUCCESS = 'CONFIRM_LOGIN_SUCCESS';
export const CONFIRM_LOGIN_FAILURE = 'CONFIRM_LOGIN_FAILURE';

export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const GET_TOKENS_FOR_DUPLICATED_TAB = 'GET_TOKENS_FOR_DUPLICATED_TAB';

export const LOGIN_COUNTDOWN_TIME = 180000;

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export interface SetPasscodePayload {
    key: string,
    value: string,
}
export const SET_PASSCODE_DIGIT = 'SET_PASSCODE_DIGIT';
export const CLEAR_ALL_PASSCODE_DIGITS = 'CLEAR_ALL_PASSCODE_DIGITS';

export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
