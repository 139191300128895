import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const StandardNumber = ({ fontSize = 16, children, ...props }: TextProps) => {
    return (
        <Text fontFamily={'monospace'} fontSize={fontSize} {...props}>
            {children}
        </Text>
    );
};
export default StandardNumber;
