import { HttpBackendOptions } from 'i18next-http-backend';

export const createBackendOptions = (localesManifest: Record<string, string>): HttpBackendOptions => {

    return {
        loadPath: '{{lng}}|{{ns}}', // used to pass language and namespace to request function
        /* eslint-disable-next-line max-params */ // XXX this is an external lib, this one has to be ignored by eslintcc
        request: (options, url, payload, callback) => {
            // const manifestData =  JSON.parse(options.customHeaders?.localesManifest);
            const [lang, ns] = url.split('|');
            return fetch(localesManifest[`/locales/${lang}/${ns}.json`], { cache: 'force-cache' })

                .then((response) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    !response.ok
                        ? callback(null, { status: response.status, data: '' })
                        : response.text().then((data) => {
                            callback(null, { status: response.status, data });
                        });
                });
        },
    };
};
