import rtlDetect from 'rtl-detect';
import { getLocalStorageObjectItem } from '@manigo/manigo-commons';

import { defaultLocale, selectedLanguageStorageKay } from 'config/config';

export function getStoredLanguage() {
    return getLocalStorageObjectItem(selectedLanguageStorageKay) || defaultLocale;
}

export function getDirectionFromLocale(locale: string): any {
    return rtlDetect.getLangDir(locale);
}

export default { getStoredLanguage };
