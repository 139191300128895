import React, { useMemo } from 'react';
import { useColorModeValue } from '@chakra-ui/react';

import { getDefaultLightDarkValues } from 'utils/theme-tools';

import { StandardTooltipProps } from './StandardTooltip.types';
import Styled from './StandardTooltip.styled';

function StandardTooltip({
    children,
    titleColor,
    bgColor,
    title,
    showCopyToClipboard,
    clipboardContent,
    dispatchSetClipboardContent,
    placement = 'top',
    defaultOpen,
    isDisabled,
    hasArrow,
    t,
}: StandardTooltipProps) {
    const isInClipboard = useMemo(
        () => showCopyToClipboard && clipboardContent === title,
        [clipboardContent, title, showCopyToClipboard],
    );

    const finalTitle = showCopyToClipboard
        ? (
            <>
                <Styled.TooltipValue>{title}</Styled.TooltipValue>
                <Styled.TooltipCopy2Clipboard>
                    {t(`${isInClipboard ? t('buttons.copyToClipboard.copiedSubtitle') : t('buttons.copyToClipboard.subtitle')}`)}
                </Styled.TooltipCopy2Clipboard>
            </>
        )
        : (<Styled.TooltipValue>{title}</Styled.TooltipValue>);

    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    return (
        <Styled.StandardTooltipWithOverriddenStyles
            label={finalTitle}
            placement={placement}
            bg={bgColor || colorScheme}
            color={titleColor}
            defaultIsOpen={defaultOpen}
            isDisabled={isDisabled}
            hasArrow={hasArrow}
            closeOnClick={!showCopyToClipboard}
            gutter={24}
            closeOnScroll
        >
            {showCopyToClipboard
                ? (
                    <Styled.CopyTransparentOverlay
                        onClick={() => {
                            if (dispatchSetClipboardContent) dispatchSetClipboardContent(title);
                        }}
                    >
                        {children || title}
                    </Styled.CopyTransparentOverlay>
                )
                : <Styled.StandardOverlay>{children || title}</Styled.StandardOverlay>}
        </Styled.StandardTooltipWithOverriddenStyles>
    );
}
export default StandardTooltip;
