import React from 'react';

import PrivacyPolicy from 'components/common/templates/PrivacyPolicy';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import StandardContentWrapper from 'components/common/atoms/StandardContentWrapper';


function PrivacyPolicyModal() {
    return (
        <ScrollableContainer>
            <StandardContentWrapper>
                <PrivacyPolicy />
            </StandardContentWrapper>
        </ScrollableContainer>
    );
}

export default PrivacyPolicyModal;
