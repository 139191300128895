import { ConsentDetails, ConsentStatus, Uuid } from '@manigo/manigo-domain-typings';

export const SET_NEW_CONSENTS_MODE = 'SET_NEW_CONSENTS_MODE';
export const CLEAR_NEW_CONSENTS_MODE = 'CLEAR_NEW_CONSENTS_MODE';
export const NEW_CONSENT_HAS_EXPIRED = 'NEW_CONSENT_HAS_EXPIRED';
export const UNAUTHORISED_DECLINE_CONSENT_REQUEST = 'UNAUTHORISED_DECLINE_CONSENT_REQUEST';

export const NEW_CONSENT_NOT_FOUND = 'NEW_CONSENT_NOT_FOUND';

export const CHANGE_CONSENT_STATUS = 'CHANGE_CONSENT_STATUS';
export const CHANGE_CONSENT_STATUS_SUCCESS = 'CHANGE_CONSENT_STATUS_SUCCESS';
export const CHANGE_CONSENT_STATUS_FAILURE = 'CHANGE_CONSENT_STATUS_FAILURE';


export interface SetNewConsentModePayload {
    newConsentUuid: ConsentDetails['consentId'],
    newConsentCallbackUrl: string,
}

export interface ChangeConsentStatusPayload {
    status: ConsentStatus,
    accounts: Uuid[],
    consentUuid: Uuid,
    extUserUuid: Uuid,
}

export interface UnauthorisedDeclineConsentRequestPayload {
    consentUuid: Uuid,
}

export interface ChangeConsentStatusSuccessPayload {
    status: ConsentStatus,
    accounts: Uuid[],
    consentUuid: Uuid,
    extUserUuid: Uuid,
}
