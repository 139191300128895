import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Dispatch } from '@manigo/manigo-commons';

import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { RootState } from 'store/rootReducer';
import { newConsentReducerName } from 'store/new-consent/reducer';
import { clearCurrentUser } from 'store/current-user/actions';

import SuccessWithRedirection from './SuccessWithRedirection.layout';
import { SuccessWithRedirectionDispatchProps, SuccessWithRedirectionOwnProps, SuccessWithRedirectionStateProps } from './SuccessWithRedirection.types';


const mapStateToProps = (state: RootState): SuccessWithRedirectionStateProps => ({
    redirectionUrl: state[newConsentReducerName].redirectionUrl,
    newConsentStatus: state[newConsentReducerName].newConsentStatus,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): SuccessWithRedirectionDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    dispatchClearCurrentUser: () => dispatch(clearCurrentUser()),
});


const decorate = compose<ComponentType<SuccessWithRedirectionOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SuccessWithRedirection);
