import { MdCancel, MdCheck } from 'react-icons/md';
import { TagData } from 'models/app/tag';

export const createBooleanValueHandlers = ({ value, t }): TagData => {
    const finalValue = Boolean(value);

    const handlers = [
        {
            predicate: () => finalValue,
            handler: () => ({ color: 'green', text: t('common:yes'), leftIcon: MdCheck }),
        },
        {
            predicate: () => !finalValue,
            handler: () => ({ color: 'red', text: t('common:no'), leftIcon: MdCancel }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { createBooleanValueHandlers };
