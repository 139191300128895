import { createAction } from '@reduxjs/toolkit';
import {
    CLEAR_CONSENTS_LIST,
    FETCH_CONSENTS_LIST, FETCH_CONSENTS_LIST_FAILURE, FETCH_CONSENTS_LIST_SUCCESS,
} from './actions.types';

export const fetchConsentsList = createAction(FETCH_CONSENTS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchConsentsListSuccess = createAction(
    FETCH_CONSENTS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchConsentsListFailure = createAction(FETCH_CONSENTS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearConsentsList = createAction(CLEAR_CONSENTS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
