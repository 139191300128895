import { createGlobalStyle, css } from 'styled-components';
import { WL_CONFIG } from 'config/environment';
import {
    BASE_COLOR_DARK_NUMBER,
    BASE_COLOR_NUMBER,
    getChakraVarColorWithEndpoint,
} from 'utils/theme-tools';

const { fontFamilyBody } = WL_CONFIG.themeVariables as any;

const getPinInputStyle = ({ colorSchemeVar }) => css`
  input.chakra-pin-input {
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 1px ${colorSchemeVar};
      border-color: ${colorSchemeVar};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${colorSchemeVar};
      border-color: ${colorSchemeVar};
    }

    &:active {
      outline: none;
      box-shadow: 0 0 0 1px ${colorSchemeVar};
      border-color: ${colorSchemeVar};
    }
  }
`;

const getFlatPickerStyle = () => css`
  html[data-theme="dark"] & {
    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
      background: ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)} !important;
      border-color: ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)} !important;
    }

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
      box-shadow: -10px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)} !important;
    }


    .flatpickr-day.week.selected {
      border-radius: 0;
      box-shadow: -5px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)}, 5px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)} !important;
    }

    .flatpickr-months .flatpickr-prev-month:hover svg,
    .flatpickr-months .flatpickr-next-month:hover svg {
      fill: ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)} !important;
    }

    .flatpickr-innerContainer {
      background: var(--chakra-colors-gray-700) !important;
    }

    .flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
      color: var(--chakra-colors-gray-500) !important;
    }

    .flatpickr-day {
      color: var(--chakra-colors-gray-300) !important;
    }

    .flatpickr-day.inRange {
      background: var(--chakra-colors-gray-500) !important;
      box-shadow: -5px 0 0 var(--chakra-colors-gray-500), 5px 0 0 var(--chakra-colors-gray-500);
      border-color: var(--chakra-colors-gray-500) !important;
    }

    .flatpickr-months .flatpickr-month {
      background: var(--chakra-colors-gray-400) !important
    }

    .flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
      border-bottom-color: var(--chakra-colors-gray-400) !important
    }

    .flatpickr-weekdays, .flatpickr-weekday {
      background: var(--chakra-colors-gray-400) !important
    }

    .flatpickr-monthDropdown-months {
      background: var(--chakra-colors-gray-400) !important;
      border-radius: 4px;
    }

    .flatpickr-monthDropdown-months:hover {
      background: #0000000d !important;
      border-radius: 4px;
    }

    .flatpickr-day:hover {
      background: var(--chakra-colors-gray-500) !important;
      border-color: var(--chakra-colors-gray-500) !important;
    }
  }

  html[data-theme="light"] {
    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
      background: ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important;
      border-color: ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important;
    }

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
      box-shadow: -10px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important;
    }


    .flatpickr-day.week.selected {
      border-radius: 0;
      box-shadow: -5px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}, 5px 0 0 ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important;
    }

    .flatpickr-months .flatpickr-prev-month:hover svg,
    .flatpickr-months .flatpickr-next-month:hover svg {
      fill: ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important;
    }
  }

`;
export const GlobalStyles = createGlobalStyle`

  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: ${fontFamilyBody} !important;
    overflow: hidden;
  }

  #root {
    height: 100%;
    position: relative;
  }

  .chakra-modal__body {
    overflow: unset !important;
  }

  #chakra-modal-tos-modal,
  #chakra-modal-privacyPolicy-modal
  {
    height: 100%;

    .chakra-modal__body {
      padding: 1rem 0;
    }

    .scrollableContainer-innerWrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  #chakra-modal-faq-modal{
      height: 30rem;
  }
    .chakra-menu__icon-wrapper {
        display: none;
    }
  .chakra-popover__popper {
    z-index: 9999 !important;
  }

  .chakra-collapse {
    overflow: unset !important;
  }

  .chakra-menu__menu-list {
    z-index: 99999 !important;
  }

  .app-height {
    height: 100%;
  }
  
  .chakra-menu__menu-button > span:first-of-type {
    display: flex;
  }
  
  .chakra-menu__menuitem > span:last-of-type {
    display: flex;
  }

  html[data-theme="dark"] & {
    ${() => getPinInputStyle({ colorSchemeVar: getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER) })}
  }

  html[data-theme="light"] & {
    ${() => getPinInputStyle({ colorSchemeVar: getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER) })}
  }

  ${() => getFlatPickerStyle()}
  .enter {
    animation: 0.5s enter forwards;
  }

  .exit {
    animation: 0.5s exit forwards;
  }

  /* hidden honeypot filed */
  .hpf {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    padding: 0 !important;
    margin: 0 !important;
  }

  @keyframes enter {
    from {
      opacity: 0;
      transform: translate(100px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @keyframes exit {
    from {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(-100px, 0);
      opacity: 0;
    }
  }

`;
