import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import { TheSpinnerOwnProps } from './TheSpinner.types';
import TheSpinner from './TheSpinner.layout';

const decorate = compose<ComponentType<TheSpinnerOwnProps>>(
    withTranslation('common'),
);


export default decorate(TheSpinner);
