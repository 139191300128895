import React, { useMemo, JSX } from 'react';
import { isUndefined } from '@manigo/manigo-commons';

import StandardFilters from 'components/common/molecules/StandardFilters';
import SpinnerOverlay from 'components/common/atoms/SpinnerOverlay';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';

import { StandardDataListProps } from './StandardDataList.types';
import StandardDataListFooter from './StandardDataListFooter';
import Styled from './StandardDataList.styled';
import NoResultsPlaceholder from './NoResultsPlaceholder';
import StandardDataListAccordion from './StandardDataListAccordion';


function StandardDataList<ItemDefinition extends object>({
    items,
    totalCount,
    queryParams,
    fetchData,
    isListInitialStateDefined,
    isLoadingData,
    columnsConfiguration,
    filtersConfiguration,
    t,
}: StandardDataListProps<ItemDefinition>): JSX.Element {

    const isListReady = useMemo(
        () => isListInitialStateDefined && !isUndefined(totalCount),
        [isListInitialStateDefined, totalCount],
    );
    return (
        <Styled.StandardDataListOuterWrapper className="app-height">
            <StandardFilters
                isListReady={isListReady}
                queryParams={queryParams}
                filtersConfiguration={filtersConfiguration}
                fetchData={fetchData}
            />

            <Styled.StandardDataListWrapper className="app-height">
                {totalCount === 0 && !isLoadingData ? (
                    <NoResultsPlaceholder
                        queryParams={queryParams}
                        filtersConfiguration={filtersConfiguration}
                        t={t}
                    />
                ) : (
                    <SpinnerOverlay
                        isLoading={isLoadingData}
                        dataTestId={isLoadingData ? 'data-list-refresh-spinner-active' : 'data-list-refresh-spinner-disabled'}
                    >
                        <ScrollableContainer>
                            <StandardDataListAccordion<ItemDefinition>
                                items={items}
                                queryParams={queryParams}
                                columnsConfiguration={columnsConfiguration}
                            />
                        </ScrollableContainer>
                    </SpinnerOverlay>
                )}
            </Styled.StandardDataListWrapper>

            <StandardDataListFooter
                t={t}
                isListReady={isListReady}
                totalCount={totalCount}
                queryParams={queryParams}
                fetchData={fetchData}
                itemsCount={items?.length}
            />
        </Styled.StandardDataListOuterWrapper>
    );
}


export default StandardDataList;
