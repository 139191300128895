import React from 'react';

import { Alert, AlertIcon } from '@chakra-ui/react';
import { AlertInfoProps } from './AlertInfo.types';

const AlertInfo = ({
    status,
    message,
    margin,
    ...alertProps
}: AlertInfoProps) => {
    return (
        <Alert
            status={status}
            margin={margin}
            borderRadius={'md'}
            transition={'all'}
            transitionDuration={'300ms'}
            {...alertProps}
        >
            <AlertIcon />
            {message}
        </Alert>
    );

};

export default (AlertInfo);
