import React, { JSX } from 'react';
import { useColorMode } from '@chakra-ui/system';
import { MdPerson } from 'react-icons/md';

import { Box, Grid } from '@chakra-ui/react';
import ButtonMenu from 'components/common/molecules/ButtonMenu';
import TheIcon from 'components/common/atoms/TheIcon';

import { UserDropdownProps } from './UserDropdown.types';
import { createUserDropdownItemsConfig } from './UserDropodown.helpers';

function UserDropdown({
    t,
    firstName,
    lastName,
    fullName,
    dispatchClearCurrentUser,
    dispatchShowConfirmation,
    dispatchShowModal,
}: UserDropdownProps): JSX.Element {
    const { toggleColorMode } = useColorMode();

    return (
        <ButtonMenu
            header={(
                <Grid gridTemplateColumns="1fr" gap={1} paddingLeft={4} paddingRight={4}>
                    <Box fontSize=".875rem">{t('common:appUi.mainMenuItems.userInfo.heading')}</Box>
                    <Box fontWeight="600">{fullName || `${firstName} ${lastName}`}</Box>
                </Grid>
            )}
            label={<TheIcon Icon={MdPerson} />}
            items={createUserDropdownItemsConfig({
                t,
                dispatchClearCurrentUser,
                dispatchShowModal,
                toggleColorMode,
            }).map((item) => ({
                id: item.id,
                key: item.id,
                icon: item.icon,
                label: item.label,
                onClick: item.confirmation ? () => dispatchShowConfirmation(item.confirmation as any) : item.onClick,
                dataTestId: item.dataTestId,
                addDividerTop: item.addDividerTop,
            }))}
        />
    );
}

export default UserDropdown;
