import styled from 'styled-components';

const AnimatedCheckMarkWrapper = styled.div<{ $size?: string, $colorScheme: string }>`
  .checkmark {
    width: ${({ $size }) => ($size)};
    height: ${({ $size }) => ($size)};
    border-radius: 50%;
    display: block;
    stroke-miterlimit: 10;
    animation: scale .3s ease-in-out .9s both;
    position: relative;
    margin: 0 auto;
    stroke: ${({ $colorScheme }) => ($colorScheme)};
  }

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 3px;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2px;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }

    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
`;

export default { AnimatedCheckMarkWrapper };
