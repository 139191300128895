import React, { JSX, useMemo } from 'react';

import StandardDetailsCards from 'components/common/molecules/StandardDetailsCards';

import { ConsentDetailsDisplayProps } from './ConsentDetailsDisplay.types';
import { createNewConsentDetailsSectionsConfig } from './ConsentDetailsDisplay.helpers';


function ConsentDetailsDisplay({ consentDetails, t }: ConsentDetailsDisplayProps): JSX.Element {
    const consentDetailsSectionsConfig = useMemo(
        () => (consentDetails?.consentId
            ? createNewConsentDetailsSectionsConfig({ t, consentScope: consentDetails?.scope })
            : []),
        [t, consentDetails?.consentId],
    );

    return (
        <div className="newConsentDetails">
            <StandardDetailsCards
                item={consentDetails}
                cardsConfiguration={consentDetailsSectionsConfig}
            />
        </div>
    );
}

export default ConsentDetailsDisplay;
