import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import { currentUserReducerName } from 'store/current-user/reducer';
import CopyrightNotice from 'components/common/atoms/CopyrightNotice/CopyrightNotice.layout';

const mapStateToProps = (state: RootState) => ({ accessTokenExpirationTimeStamp: state[currentUserReducerName].accessTokenExpirationTimeStamp });

const decorate = compose<ComponentType<{ namespace?:string }>>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(CopyrightNotice);

