import React, { Fragment } from 'react';
import {
    Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spinner,
} from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { ButtonConfiguration } from 'models/app/common';
import TheIcon from 'components/common/atoms/TheIcon';
import StandardTooltip from 'components/common/atoms/StandardTooltip';

import { ButtonMenuProps } from './ButtonMenu.types';

import Styled from './ButtonMenu.styled';

function ButtonMenu({
    header,
    label,
    leftIcon,
    items,
}: ButtonMenuProps) {
    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        isActive={isOpen}
                        as={Button}
                        rightIcon={<TheIcon Icon={isOpen ? MdExpandLess : MdExpandMore} />}
                        {...(leftIcon ? { leftIcon: <TheIcon Icon={leftIcon} /> } : {})}
                    >
                        {label}
                    </MenuButton>
                    <MenuList>
                        {header}
                        {items?.map((item:ButtonConfiguration) => {
                            const Wrapper = item?.tooltipTitle ? {
                                Component: StandardTooltip,
                                props: { title: item.tooltipTitle },
                            } : { Component: React.Fragment, props: {} as any };
                            return (
                                <>
                                    {item.addDividerTop && <MenuDivider />}
                                    <Wrapper.Component {...Wrapper.props} key={item.id}>
                                        <MenuItem
                                            alignContent="center"
                                            alignItems="center"
                                            key={item.id}
                                            onClick={item.onClick}
                                            icon={(
                                                <Styled.MenuItem>
                                                    {item.isLoading && <Spinner size="sm" mr={'2'} />}
                                                    {item.icon && <TheIcon Icon={item.icon} />}
                                                </Styled.MenuItem>
                                            )}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    </Wrapper.Component>
                                </>
                            );
                        })}
                    </MenuList>
                </>
            )}
        </Menu>
    );
}

export default ButtonMenu;
