import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { LocationChangeRequest, Dispatch } from '@manigo/manigo-commons';

import { RootState } from 'store/rootReducer';
import { requestNavigation } from 'store/navigation/actions';
import { clearCurrentUser } from 'store/current-user/actions';
import { showConfirmation, ShowConfirmationPayload } from 'store/alert/actions';
import { currentUserReducerName } from 'store/current-user/reducer';
import { showModal } from 'store/modal/actions';

import Sidebar from './Sidebar.layout';
import { SidebarDispatchProps, SidebarStateProps } from './Sidebar.types';


const mapStateToProps = (state: RootState): SidebarStateProps => ({
    enhancedCurrentLocation: state.navigation.currentLocation,
    firstName: state[currentUserReducerName]?.userData?.user.firstName,
    lastName: state[currentUserReducerName]?.userData?.user.lastName,
    fullName: state[currentUserReducerName]?.userData?.user.fullName,
});

const mapDispatchToProps = (dispatch: Dispatch): SidebarDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) =>
        dispatch(requestNavigation({ locationPathname, meta })),
    dispatchClearCurrentUser: () => dispatch(clearCurrentUser()),
    dispatchShowConfirmation: (payload:ShowConfirmationPayload) => dispatch(showConfirmation(payload)),
    dispatchShowModal: (payload) => dispatch(showModal(payload)),
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Sidebar);
