import { BASE_API_URL, SUPPORTED_LANGUAGES, TIME_TO_AUTO_LOGOUT_COUNTDOWN } from './environment';

export const baseApiUrl = BASE_API_URL;

export const localesManifestFileName = 'locales-manifest.json';
export const jwtTokenKey = 'jwt';
export const newConsentKey = 'newConsent';
export const sessionUuidKey = 'sessionUuid';
export const permissionsKey = 'permissions';
export const primaryColorSchemeKey = 'BWP_colorSchemeKey';
export const secondaryColorSchemeKey = 'BWP_secondaryColorSchemeKey';
export const localesManifestKey = 'localesManifest';
export const userPreferencesKey = 'preferences';

export const selectedLanguageStorageKay = 'BWP_selectedLanguage';
export const timeToAutoLogoutCountDownBanner = (Number(TIME_TO_AUTO_LOGOUT_COUNTDOWN) || 4) * 60 * 1000; // in milliseconds


export const standardDateFormat = 'DD/MM/YYYY';
export const standardDateAndTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const standardTimeFormat = 'HH:mm:ss';
export const usTimeFormat = 'h:mm:ss A';

export const isoDateFormat = 'YYYY-MM-DD';
export const standardPageSize = 6;

export const enGbLocale = 'en-GB';

export const defaultLocale = SUPPORTED_LANGUAGES.find((locale) => locale.default)?.key || enGbLocale;
