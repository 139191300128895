import React, { JSX } from 'react';
import { useColorMode } from '@chakra-ui/system';
import useScrollThemeCss from 'hook/useScrollThemeCss';

import { ScrollableContainerProps } from './ScrollableContainer.types';
import Styled from './ScrollableContainer.styled';

function ScrollableContainer({ children }: ScrollableContainerProps): JSX.Element {
    const { colorMode } = useColorMode();
    const scrollCSS = useScrollThemeCss();

    return (
        <Styled.ScrollableContainer
            className={`${colorMode} app-height`}
            $scrollCSS={scrollCSS}
        >
            <Styled.ScrollableContainerInnerWrapper className="scrollableContainer-innerWrapper">
                {children}
            </Styled.ScrollableContainerInnerWrapper>
        </Styled.ScrollableContainer>
    );
}

export default ScrollableContainer;
