import styled from 'styled-components';

export const StandardDataListAccordion = styled.div`
    margin-top: 0;
`;


export const StandardDataListAccordionHeaderItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StandardDataListAccordionHeaderItemTitle = styled.div<{ $columnAlign?: string }>`
    font-weight: bold;
    font-size: 1rem;
    color: var('--chakra-colors-gray-700');
    text-align: ${({ $columnAlign }) => $columnAlign};
    white-space: nowrap;
`;

export const StandardDataListAccordionHeaderItemValue = styled.div<{ $columnAlign?: string }>`
    text-align: ${({ $columnAlign }) => $columnAlign};
    font-size: .875rem;
    display: flex;
    justify-content: ${({ $columnAlign }) => {
        const handlers = [
            {
                predicate: () => $columnAlign === 'left',
                handler: () => 'flex-start',
            },
            {
                predicate: () => $columnAlign === 'right',
                handler: () => 'flex-end',
            },
            {
                predicate: () => $columnAlign === 'center',
                handler: () => 'center',
            },
            {
                predicate: () => true,
                handler: () => 'flex-start',
            },
        ];
        return handlers.find(({ predicate }) => predicate())?.handler();
    }};
`;

export const StandardDataListAccordionContent = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StandardDataListAccordionContentItem = styled.div`
    display: flex;
    align-items: baseline;
    gap: .5rem;
    margin: 1rem 0;
`;
export const StandardDataListAccordionContentItemTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    color: var('--chakra-colors-gray-700');
    text-transform: capitalize;
    text-align: left;
    white-space: nowrap;
`;
export const StandardDataListAccordionContentItemValue = styled.div<{ $columnAlign?: string }>`
    text-align: ${({ $columnAlign }) => $columnAlign};
    width: 100%;
    font-size: .875rem;
`;
export default {
    StandardDataListAccordion,
    StandardDataListAccordionHeaderItem,
    StandardDataListAccordionHeaderItemTitle,
    StandardDataListAccordionHeaderItemValue,
    StandardDataListAccordionContent,
    StandardDataListAccordionContentItem,
    StandardDataListAccordionContentItemTitle,
    StandardDataListAccordionContentItemValue,
};
