import React, { JSX } from 'react';

import { WL_CONFIG } from 'config/environment';
import Styled from './BrandAnimatedLoadingPlaceholder.styled';

function BrandAnimatedLoadingPlaceholder(): JSX.Element {
    return (
        <Styled.BrandAnimatedLoadingPlaceholder>
            <img
                src={WL_CONFIG.brandAnimatedLoadingPlaceholderUrl || '/default-animated-loading-placeholder.svg'}
                alt="animated placeholder"
            />
        </Styled.BrandAnimatedLoadingPlaceholder>
    );
}


export default BrandAnimatedLoadingPlaceholder;
