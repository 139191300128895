import { ReactNode } from 'react';
import { SelectFieldOptionalConfiguration, SelectOptionFactory } from './formContols';

interface TableNoDataConfig {
    title?: ReactNode;
    children?: ReactNode;
}

export enum FilterInputType {
        SELECT = 'SELECT',
        SWITCH = 'SWITCH',
}

export interface Filter {
    dataTestId?: SelectFieldOptionalConfiguration['dataTestId'],
    queryParamName: string,
    label?: string,
    filterOptions: SelectOptionFactory[],
    isSearchable?: boolean,
    isMultiSelect?: boolean,
    customItemRenderer?: SelectFieldOptionalConfiguration['customItemRenderer'],
    customContentRenderer?: SelectFieldOptionalConfiguration['customContentRenderer'],
    placeholderText?: string,
    searchBy?: SelectFieldOptionalConfiguration['searchBy'],
    filterInputType?: FilterInputType,
}


export interface StandardFiltersConfiguration {
    // common stuff
    searchBy?: SelectOptionFactory[] // if present acts as hasSearch bool flag,
    sortByOptions?: SelectOptionFactory[] // if present acts as hasSorters bool flag
    // screen specific filters
    filtersConfig?: Filter[];
    isSearchable?: boolean;
    filterActiveNoDataConfig?: TableNoDataConfig;
    filterInactiveNoDataConfig?: TableNoDataConfig
}
