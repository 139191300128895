import React from 'react';
import { withTranslation } from 'react-i18next';

import StandardTag from 'components/common/atoms/StandardTag';
import { ConsentStatusTagProps } from './ConsentStatusTag.types';
import { createConsentStatusTagHandlers } from './ConsentStatusTag.helpers';

function ConsentStatusTag({ value, t }: ConsentStatusTagProps) {
    const tagData = createConsentStatusTagHandlers({ value, t });

    return (
        <StandardTag
            color={tagData.color}
            value={tagData.text}
        />
    );
}

export default withTranslation('common')(ConsentStatusTag);
