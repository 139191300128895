import React, { JSX } from 'react';
import { Box, HStack, Skeleton, Switch } from '@chakra-ui/react';

import { FilterInputType } from 'models/app/standardFilters';
import { FormInputTypes } from 'models/app/formContols';
import Styled from '../StandardFilters.styled';
import StandardSelect from '../../StandardSelect';


function FilterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    isListReady,
}): JSX.Element {

    // TODO add handlers and render also multi-selects and checkboxes
    return filtersConfiguration.filtersConfig && filtersConfiguration.filtersConfig?.length > 0
        ? (
            <>
                {(filtersConfiguration.filtersConfig || []).map((filter) => {
                    return (
                        <Styled.FilterWrapper key={filter.queryParamName}>
                            {
                                isListReady ? (
                                    <>
                                        <Styled.FilterLabel>
                                            {filter.label}
                                        </Styled.FilterLabel>

                                        {filter.filterInputType === FilterInputType.SWITCH && (
                                            <HStack alignItems={'center'}>
                                                <Box>{filter.filterOptions[0].label}</Box>
                                                <Switch
                                                    onChange={({ target: { value } }) => {
                                                        handleQueryParamsChange({
                                                            fieldName: filter.queryParamName,
                                                            value: value !== 'true',
                                                            resetOffset: true,
                                                        });
                                                    }}
                                                    isChecked={queryParams?.[filter.queryParamName]}
                                                    value={queryParams?.[filter.queryParamName]}
                                                    size="lg"
                                                    data-test-id={filter.dataTestId}
                                                />
                                            </HStack>
                                        )}
                                        {filter.filterInputType !== FilterInputType.SWITCH && (
                                            <StandardSelect
                                                fieldConfiguration={{
                                                    placeholderText: filter.placeholderText,
                                                    id: filter.queryParamName,
                                                    name: `filter-${filter.queryParamName}`,
                                                    dataTestId: `filter-${filter.dataTestId}`,
                                                    fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                                    labelText: '',
                                                    optionalConfiguration: {
                                                        options: filter.filterOptions,
                                                        isClearable: true,
                                                        isSearchable: filter?.isSearchable,
                                                        isMultiSelect: filter?.isMultiSelect,
                                                        customItemRenderer: filter?.customItemRenderer,
                                                        customContentRenderer: filter?.customContentRenderer,
                                                        searchBy: filter?.searchBy,
                                                    },
                                                    value: queryParams?.[filter.queryParamName],
                                                    onChange: (newValue) => {
                                                        handleQueryParamsChange({
                                                            fieldName: filter.queryParamName,
                                                            value: newValue,
                                                            resetOffset: true,
                                                        });
                                                    },
                                                }}
                                            />
                                        )}
                                    </>
                                )
                                    : <Skeleton height="50px" width="100%" rounded="md" key={filter.queryParamName} />
                            }
                        </Styled.FilterWrapper>
                    );

                })}
            </>
        )
        : <></>;
}

export default FilterFields;
