import React from 'react';

import TheSpinner from 'components/common/atoms/TheSpinner';

import { SpinnerOverlayProps } from './SpinnerOverlay.types';
import Styled from './SpinnerOverlay.styled';


function SpinnerOverlay({
    children,
    isLoading,
    dataTestId,
}: SpinnerOverlayProps): JSX.Element {
    return (
        <Styled.SpinnerOverlayWrapper className="app-height" data-test-id={dataTestId}>
            {isLoading
                ? (
                    <Styled.SpinnerWrapper className="app-height">
                        <TheSpinner />
                    </Styled.SpinnerWrapper>
                )
                : (<></>)}

            <Styled.SpinnerOverlayContentWrapper>
                {children}
            </Styled.SpinnerOverlayContentWrapper>
        </Styled.SpinnerOverlayWrapper>
    );
}

export default SpinnerOverlay;

