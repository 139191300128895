import React, { useState, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, keyframes } from '@chakra-ui/react';
import { NavigationDirectionTypes } from '@manigo/manigo-commons';

import { RedirectionToDefaultPathTypes } from 'models/app/navigation';

import { AnimatedRoutesProps } from './AnimatedRoutes.types';
import DefaultRedirection from '../../DefaultRedirection';

const right = '100px';
const left = '-100px';

export function AnimatedRoutes({
    routesConfiguration,
    defaultRedirectionPath,
    location,
}: AnimatedRoutesProps) {
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransitionStage] = useState('enter');
    const isNavigationBack = location?.direction === NavigationDirectionTypes.BACKWARD;

    useEffect(() => {
        if (location?.pathname !== displayLocation?.pathname) setTransitionStage('exit');
    }, [location, displayLocation]);


    const animation = useMemo(() => {
        const frames = transitionStage === 'enter'
            ? keyframes`
                  from {
                    opacity: 0;
                    transform: translate(${isNavigationBack ? left : right}, 0);
                  }
                  to {
                    opacity: 1;
                    transform: translate(0px, 0px);
                  }
          `
            : keyframes`
                  from {
                    opacity: 1;
                    transform: translate(0px, 0px);
                  }
                  to {
                    transform: translate(${isNavigationBack ? right : left}, 0);
                    opacity: 0;
                  }`;


        return `${frames} 0.5s forwards`;

    }, [transitionStage, displayLocation?.direction]);


    return (
        <Box
            animation={animation}
            style={{ display: 'block', width: '100%' }}
            className="app-height"
            onAnimationEnd={() => {
                if (transitionStage === 'exit') {
                    setTransitionStage('enter');
                    setDisplayLocation(location);
                }
            }}
        >
            <Routes location={displayLocation}>
                <>
                    {routesConfiguration}

                    <Route
                        path="*"
                        key="not-found"
                        element={(
                            <DefaultRedirection
                                defaultRedirectionPath={defaultRedirectionPath}
                                navigationState={{
                                    replace: true,
                                    type: RedirectionToDefaultPathTypes.ROUTER_REDIRECTION_TO_DEFAULT_PATH,
                                    trigger: 'not_found_redirection_to_default_path',
                                    defaultRedirectionPath,
                                }}
                            />
                        )}
                    />
                </>
            </Routes>
        </Box>
    );
}
