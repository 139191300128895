import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';


import { Epic } from 'models/meta/epic';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getSessionStorageObjectItem, setLocalStorageObjectItem, setSessionStorageObjectItem } from '@manigo/manigo-commons';
import { debugMode } from 'config/environment';
import { defaultLocale, localesManifestFileName, localesManifestKey, selectedLanguageStorageKay } from 'config/config';
import { availableLocales, i18nFormat, unauthorisedNamespaces } from 'config/i18n';
import { getStoredLanguage } from 'utils/locales-tools';

import { CHANGE_LANGUAGE, GET_LOCALES_MANIFEST, INIT_I18NEXT, LOAD_LANGUAGE, LOAD_NAMESPACES } from './action.types';

import {
    changeLanguage, changeLanguageFailure, changeLanguageSuccess, getLocalesManifestSuccess, initI18next,
    initI18nextFailure, initI18nextSuccess, loadLanguageFailure,
    loadLanguageSuccess, loadNamespacesFailure, loadNamespacesSuccess,
} from './actions';
import { createBackendOptions } from './epics.helpers';
import { localesReducerName } from './reducer';

export const onGetLocalesManifest: Epic = (action$) =>
    action$.pipe(
        ofType(GET_LOCALES_MANIFEST),
        switchMap(() => {
            const localesManifestFromStorage = getSessionStorageObjectItem(localesManifestKey);

            const getActions = (localesManifestContent: any) => [
                getLocalesManifestSuccess(localesManifestContent),
                initI18next(),
            ];

            if (localesManifestFromStorage) {
                return of(...getActions(localesManifestFromStorage));
            } else {

                return from(
                    fetch(`/${localesManifestFileName}`, {
                        method: 'GET',
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    }).then((response) => response.json()),
                ).pipe(
                    switchMap(response => {
                        setSessionStorageObjectItem(localesManifestKey, response);
                        return of(...getActions(response));
                    }),
                );
            }
        }),
    );

export const onInitI18next: Epic = (action$, state$, { i18n }) => {
    return action$.pipe(
        ofType(INIT_I18NEXT),
        switchMap(() => {
            const storedLanguage = getStoredLanguage();
            const initialLanguage = availableLocales.includes(storedLanguage) ? storedLanguage : defaultLocale;
            return from(
                i18n.use(initReactI18next)
                    .use(HttpApi)
                    .init<HttpBackendOptions>({
                        debug: false,
                        partialBundledLanguages: true,
                        // debug: debugMode,
                        lng: initialLanguage,
                        ns: [...unauthorisedNamespaces],

                        interpolation: {
                            format: i18nFormat,
                            escapeValue: false,
                        },
                        load: 'currentOnly',
                        fallbackLng: defaultLocale,
                        supportedLngs: availableLocales,
                        react: { useSuspense: false },
                        saveMissing: debugMode,
                        /* eslint-disable-next-line max-params */ // XXX this is an external lib, this one has to be ignored by eslintcc
                        missingKeyHandler: () => {
                            if (debugMode) {
                                // eslint-disable-next-line no-console
                                //console.warn(`Missing ‘${lngs.join('’, ’')}’ translation key\n  key: ‘${ns}:${key}’\n  fallback: ‘${fallbackValue}’\n`);
                            }
                        },
                        backend: createBackendOptions(state$.value[localesReducerName].localesManifest),
                    }),
            ).pipe(
                switchMap(() => of(initI18nextSuccess(storedLanguage))),
                catchError(() => of(initI18nextFailure())),
            );
        }),
    );
};

export const onLoadNamespaces: Epic = (action$, _, { i18n }) => {
    return action$.pipe(
        ofType(LOAD_NAMESPACES),
        switchMap(({ payload }) => {
            return from(i18n.loadNamespaces(payload)).pipe(
                switchMap(() => of(loadNamespacesSuccess(payload))),
                catchError(() => of(loadNamespacesFailure(payload))),
            );
        }),
    );
};

export const onLoadLanguage: Epic = (action$, _, { i18n }) => {
    return action$.pipe(
        ofType(LOAD_LANGUAGE),
        switchMap(({ payload }) => {
            return from(i18n.loadLanguages(payload)).pipe(
                switchMap(() => of(
                    loadLanguageSuccess(payload),
                    changeLanguage(payload),
                )),
                catchError(() => of(loadLanguageFailure(payload))),
            );
        }),
    );
};

export const onChangeLanguage: Epic = (action$, _, { i18n }) => {
    return action$.pipe(
        ofType(CHANGE_LANGUAGE),
        switchMap(({ payload }) => {
            return from(i18n.changeLanguage(payload)).pipe(
                switchMap(() => {
                    setLocalStorageObjectItem(selectedLanguageStorageKay, payload);
                    return of(changeLanguageSuccess(payload));
                }),
                catchError(() => of(changeLanguageFailure(payload))),
            );
        }),
    );
};

export default [
    onGetLocalesManifest,
    onInitI18next,
    onLoadLanguage,
    onChangeLanguage,
    onLoadNamespaces,
];
