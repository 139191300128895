import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import withAccessControl from 'hoc/withAccessControl';
import { RootState } from 'store/rootReducer';
import { currentUserReducerName } from 'store/current-user/reducer';

import NewConsentForm from './NewConsentForm.layout';
import { NewConsentFormOwnProps, NewConsentFormStateProps } from './NewConsentForm.types';


const mapStateToProps = (state: RootState): NewConsentFormStateProps => ({
    accounts: state[currentUserReducerName].accounts,
    //
});


const decorate = compose<ComponentType<NewConsentFormOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(NewConsentForm);
