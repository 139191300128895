import { Language, Namespace } from 'models/app/i18n';

import { enGbLocale } from './config';
import { SUPPORTED_LANGUAGES } from './environment';

export const availableLanguagesLanguageNativeNames = SUPPORTED_LANGUAGES?.reduce((acc, curr) => {
    acc[curr.key] = curr.label;
    return acc;
}, {});

export const availableLocales: Language[] = Object.keys(availableLanguagesLanguageNativeNames) ?? [enGbLocale];


export const authorisedNamespaces: Namespace[] = [
    'currencies',
    'faq',
];

export const unauthorisedNamespaces: Namespace[] = [
    'unauthorised',
    'countries',
    'termsOfService',
    'privacyPolicy',
    'consent',
];

export const i18nFormat = (value) => {
    if (!value) {
        return '';
    }

    return value;
};
