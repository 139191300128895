import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import PrivacyPolicy from './PrivacyPolicy.layout';


const decorate = compose<ComponentType>(
    withTranslation('privacyPolicy'),
);

export default decorate(PrivacyPolicy);
