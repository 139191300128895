import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { compose } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { localesReducerName } from 'store/locales/reducer';
import Main from './Main.layout';

const mapStateToProps = (state: RootState) => ({ selectedLocale: state[localesReducerName].selectedLocale });

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(Main);
