import { createReducer } from '@reduxjs/toolkit';

import { addLoadingState } from 'utils/redux-tools';

import {
    CHANGE_LANGUAGE_SUCCESS, GET_LOCALES_MANIFEST_SUCCESS,
    INIT_I18NEXT_SUCCESS, LOAD_LANGUAGE, LOAD_LANGUAGE_FAILURE, LOAD_LANGUAGE_SUCCESS,
    LOAD_NAMESPACES, LOAD_NAMESPACES_FAILURE, LOAD_NAMESPACES_SUCCESS,

} from './action.types';

export interface LocalesStateType {
    readonly isI18nReady: boolean;
    readonly selectedLocale?: string;

    readonly isLoadingNamespaces: boolean,
    readonly isLoadingLanguage: boolean,
    readonly localesManifest: Record<string, string>,
}

const initialState: LocalesStateType = {
    localesManifest: {},
    isI18nReady: false,
    selectedLocale: undefined,
    isLoadingNamespaces: false,
    isLoadingLanguage: false,

};
export const localesReducerName = 'locales';

const createLocalesBuilderCases = (builder) => {
    builder
        .addCase(GET_LOCALES_MANIFEST_SUCCESS, (state, action) => {
            state.localesManifest = action.payload;
        })
        .addCase(INIT_I18NEXT_SUCCESS, (state, action) => {
            state.isI18nReady = true;
            state.selectedLocale = action.payload;
        })
        .addCase(CHANGE_LANGUAGE_SUCCESS, (state, action) => {
            state.selectedLocale = action.payload;
        });

    addLoadingState([LOAD_NAMESPACES, LOAD_NAMESPACES_SUCCESS, LOAD_NAMESPACES_FAILURE],
        'isLoadingNamespaces',
        builder);

    addLoadingState([LOAD_LANGUAGE, LOAD_LANGUAGE_SUCCESS, LOAD_LANGUAGE_FAILURE],
        'isLoadingLanguage',
        builder);
};

export default createReducer(initialState, createLocalesBuilderCases);
