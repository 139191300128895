import React, { useMemo, JSX } from 'react';
import { RouteConfiguration } from '@manigo/manigo-commons';

import { approveNewConsentRootRoutePath } from 'config/routes';
import AuthorisedLayout from 'components/App/layouts/AuthorisedLayout';
import NewConsentPage from 'components/pages/new-consent/NewConsentPage';

import { hasAccessRights, renderRoutes } from '../ApplicationContent.helpers';
import { AnimatedRoutes } from '../AnimatedRoutes/AnimatedRoutes.layout';


const createOnboardingRoutesConfiguration = (): RouteConfiguration[] => [
    {
        path: approveNewConsentRootRoutePath,
        element: <NewConsentPage />,
    },
];

function AuthorisedNewConsent({ accessControl, enhancedCurrentLocation }): JSX.Element {
    const onboardingRoutesConfiguration = createOnboardingRoutesConfiguration();

    const defaultRedirectionPath = approveNewConsentRootRoutePath;
    const getAvailableAuthorisedRoutes = (routesConfiguration) => {
        const availableRoutes = routesConfiguration.filter((routeConfiguration) => hasAccessRights({
            routeConfiguration,
            accessControl,
        }));

        return renderRoutes({ routesConfiguration: availableRoutes });
    };

    const authorisedRoutes = useMemo(
        () => (getAvailableAuthorisedRoutes(onboardingRoutesConfiguration)),
        [onboardingRoutesConfiguration, accessControl],
    );

    return (
        <AuthorisedLayout>
            <AnimatedRoutes
                routesConfiguration={authorisedRoutes}
                defaultRedirectionPath={defaultRedirectionPath}
                location={enhancedCurrentLocation}
            />
        </AuthorisedLayout>
    );

}


export default AuthorisedNewConsent;

