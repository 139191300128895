import { JSX } from 'react';
import { GridItemConfig } from './gridConfig';

export interface ColumnConfiguration<ItemDefinition> {
        title?: string,
        infoTooltip?: string,
        dataIndex?: string,
        align?: TableColumnAlign,
        isSortable?: boolean,
        isAction?: boolean,
        isBadge?: boolean,
        hideBoxShadow?: boolean,
        render?: (value: any, record: ItemDefinition) => JSX.Element,
        gridItemConfig?: GridItemConfig
}

export interface TableColumnConfiguration<ItemDefinition> {
    idName: string,
    default: ColumnConfiguration<ItemDefinition> [],
    expanded?: ColumnConfiguration<ItemDefinition> [],
}

export type CardColumnConfiguration<ItemDefinition> = ColumnConfiguration<ItemDefinition>;

export enum TableColumnAlign {
    center = 'center',
    left = 'left',
    right = 'right',
}
