import React from 'react';
import { TFunction } from 'i18next';
import { AccountType } from '@manigo/manigo-domain-typings';
import { capitalizeFirstLetter } from '@manigo/manigo-commons';

import { TagData } from 'models/app/tag';

import StandardTag from '../index';


const createHandlers = (t: TFunction, accountType?: AccountType): TagData => {
    const normalisedAccountType = capitalizeFirstLetter(accountType);

    const handlers = [
        {
            predicate: () => normalisedAccountType === AccountType.Personal,
            handler: () => ({ color: 'green', text: t(`consent:accountItem.accountType.values.${accountType}`) }),
        },
        {
            predicate: () => normalisedAccountType === AccountType.Business,
            handler: () => ({ color: 'blue', text: t(`consent:accountItem.accountType.values.${accountType}`) }),
        },
        {
            predicate: () => normalisedAccountType === AccountType.Technical,
            handler: () => ({ color: 'cyan', text: t(`consent:accountItem.accountType.values.${accountType}`) }),
        },
        {
            predicate: () => normalisedAccountType === AccountType.Base,
            handler: () => ({ color: 'orange', text: t(`consent:accountItem.accountType.values.${accountType}`) }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: t('consent:accountItem.accountType.values.Unknown') }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function AccountTypeTag({ accountType, t }: { accountType?: AccountType, t: TFunction }) {
    const tagData = createHandlers(t, accountType);

    return <StandardTag color={tagData.color} value={tagData.text} />;
}


export default AccountTypeTag;

