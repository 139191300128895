import styled from '@emotion/styled';
import { Box, chakra } from '@chakra-ui/react';

const Wrapper = styled(Box)`
  display: flex;
  cursor: pointer;
  width: 100%;
  padding-left: 10px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > input {
    border: none;
    padding: 0;
    outline: none;
    width: 100%;
    background: transparent;
  }
`;

const Placeholder = chakra(Box, { baseStyle: { color: 'gray.500' } });

export default { Wrapper, Placeholder };
