import React, { useMemo, JSX, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
    Box,
    Button, HStack, VStack, FormErrorMessage,
    ScaleFade, FormControl,
} from '@chakra-ui/react';

import { MdSend } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';

import { StandardFormMode, StandardFormType } from 'models/app/standardForm';
import { LanguageDirection } from 'models/app/i18n';
import { useLanguageDirection } from 'hook/useLanguageDirection';
import FormField from 'components/common/molecules/FormFieldWrapper';
import TheIcon from 'components/common/atoms/TheIcon';
import StandardTooltip from 'components/common/atoms/StandardTooltip';

import { StandardFormProps } from './StandardForm.types';


export const renderFormError = (formError?: string) => {
    return formError
        ? (
            <div style={{ position: 'relative' }}>
                <FormControl isInvalid>
                    <ScaleFade initialScale={0.9} in={formError?.length > 0}>
                        <FormErrorMessage fontSize="md" textAlign="center">
                            {formError}
                        </FormErrorMessage>
                    </ScaleFade>
                </FormControl>
            </div>
        ) : null;
};

// TODO: refactor to decrease complexity
// eslint-disable-next-line complexity
function StandardForm({
    mode = StandardFormMode.CREATE,
    type = StandardFormType.INLINE,
    formName,
    initialFormState = {},
    formError,
    submitButtonText,
    isSubmitButtonDisabled,
    isLoading,
    createFieldsConfiguration,
    dispatchOnSubmitAction,
    validationSchema,
    dispatchHideModal,
    watchedFormValueNames = [],
    setWatchedFormValues,
    t,
}: StandardFormProps): JSX.Element {
    const languageDirection = useLanguageDirection();
    const reverseIcons = languageDirection === LanguageDirection.rtl;

    const formObject = useForm<typeof initialFormState>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: initialFormState,
        ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
    });
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting, isDirty },
    } = formObject;

    const onSubmit = (data) => {
        dispatchOnSubmitAction({ ...data });
    };

    const formFieldsConfiguration = useMemo(
        () => createFieldsConfiguration({ t }),
        [t, createFieldsConfiguration],
    );

    const formData = useWatch({
        control,
        name: watchedFormValueNames,
    });
    useEffect(() => {
        if (setWatchedFormValues) setWatchedFormValues(formData);
    }, [formData]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            name={formName}
            noValidate
        >
            <VStack
                spacing={5}
                align="stretch"
            >
                <FormProvider {...formObject}>
                    {formFieldsConfiguration.map((fieldConfiguration) => (
                        <Box key={fieldConfiguration.name}>
                            <FormField
                                errors={errors}
                                control={control}
                                fieldConfiguration={fieldConfiguration}
                            />
                        </Box>
                    ))}
                </FormProvider>

                {renderFormError(formError)}

                <HStack justify={'flex-end'}>
                    {type === StandardFormType.MODAL && (
                        <Button
                            variant="outline"
                            type="button"
                            isDisabled={isLoading || !!isSubmitting}
                            onClick={dispatchHideModal}
                        >
                            {t('common:buttons.cancel.text')}
                        </Button>
                    )}

                    <StandardTooltip
                        title={t('common:form.noChanges')}
                        isDisabled={isDirty}
                    >
                        <Button
                            type="submit"
                            isDisabled={isLoading || (mode === StandardFormMode.EDIT && !isDirty) || isSubmitButtonDisabled}
                            isLoading={isLoading || !!isSubmitting}
                            rightIcon={reverseIcons ? <TheIcon Icon={MdSend} /> : undefined}
                            leftIcon={reverseIcons ? undefined : <TheIcon Icon={MdSend} />}
                            data-test-id={`${formName}-submit-button`}
                        >
                            {submitButtonText || t('common:buttons.submit.text')}
                        </Button>
                    </StandardTooltip>
                </HStack>
            </VStack>
        </form>
    );
}


export default StandardForm;
