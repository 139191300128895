import React, { JSX } from 'react';
import {
    useBreakpointValue,
    useDisclosure,
    Flex,
    HStack,
    Drawer,
    DrawerOverlay,
    DrawerContent,
} from '@chakra-ui/react';
import ToggleButton from './ToggleButton';
import UserDropdown from './UserDropdown';
import Sidebar from './Sidebar';
import Styled from './Navbar.styled';
import AppLogo from './AppLogo';

function NavBar(): JSX.Element {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    return (
        <Styled.NavbarWrapper as="nav" zIndex="sticky" boxShadow="md">
            <Styled.NavbarInnerWrapper p={{ base: 3, lg: 4 }}>
                <Flex justify="space-between" align={'center'} gap="4">
                    <AppLogo />

                    {isDesktop ? (
                        <HStack spacing="4">
                            <UserDropdown />
                        </HStack>
                    ) : (
                        <>
                            <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
                            <Drawer
                                isOpen={isOpen}
                                placement="left"
                                onClose={onClose}
                                isFullHeight
                                preserveScrollBarGap
                                trapFocus={false}
                                portalProps={{ appendToParentPortal: true }}
                            >
                                <DrawerOverlay />
                                <DrawerContent>
                                    <Sidebar />
                                </DrawerContent>
                            </Drawer>
                        </>
                    )}
                </Flex>
            </Styled.NavbarInnerWrapper>
        </Styled.NavbarWrapper>

    );
}

export default NavBar;
