import styled from 'styled-components';

import { Box } from '@chakra-ui/react';

const OuterWrapper = styled(Box)`
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;


const ContentWrapper = styled(Box)`
  margin: 0 auto;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;


export const ContentRouteWrapper = styled.div`
  outline: 1px solid transparent;
  justify-content: center;
  width: 100%;
  
  @media (min-width: 768px) {
    height: 100%;
  }
`;

export default {
    OuterWrapper,
    ContentWrapper,
    ContentRouteWrapper,
};
