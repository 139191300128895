import { ConsentDetails, ConsentStatus } from '@manigo/manigo-domain-typings';

import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { normaliseTellMoneyDates } from 'utils/date-time-tools';

import { FETCH_CONSENTS_LIST } from './actions.types';
import { fetchConsentsListSuccess, fetchConsentsListFailure } from './actions';


export const onFetchConsentsList: Epic = (action$, state$, { consents }) => action$.pipe(
    ofType(FETCH_CONSENTS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(consents.fetchConsents({
        ...queryParams,
        status: queryParams?.fetchAllConsents ? undefined : ConsentStatus.Authorised,
    }))
        .pipe(
            switchMap((response) => of(fetchConsentsListSuccess({
                items: response.data.map((consent: ConsentDetails) => normaliseTellMoneyDates(consent)),
                totalCount: response.data?.length,
                locationPathname,
            }))),
            catchError(() => of(fetchConsentsListFailure(locationPathname))),
        )),
);


export default [
    onFetchConsentsList,
];
