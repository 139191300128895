import styled from '@emotion/styled';

export const SubSectionTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    color: var(--chakra-colors-gray-700);
    border-bottom: 1px solid var(--chakra-colors-gray-300);
`;

export const ConsentedAccountsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;

    @media (min-width: 500px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    }
`;

export const RevokeConsentWrapper = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

export default {
    SubSectionTitle,
    ConsentedAccountsWrapper,
    RevokeConsentWrapper,
};
