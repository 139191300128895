import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { localesReducerName } from 'store/locales/reducer';

import LoadingLocalesPlaceholder from './LoadingLocalesPlaceholder.layout';
import { LoadingLocalesPlaceholderOwnProps, LoadingLocalesPlaceholderStateProps } from './LoadingLocalesPlaceholder.types';

const mapStateToProps = (state: RootState): LoadingLocalesPlaceholderStateProps => ({
    isLoadingLanguage: state[localesReducerName].isLoadingLanguage,
    isLoadingNamespaces: state[localesReducerName].isLoadingNamespaces,
    //
});

const decorate = compose<ComponentType<LoadingLocalesPlaceholderOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);
export default decorate(LoadingLocalesPlaceholder);
