import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { currentUserReducerName } from 'store/current-user/reducer';

import { StandardNumberInputOwnProps, StandardNumberInputStateProps } from './StandardNumberInput.types';
import StandardNumberInput from './StandardNumberInput.layout';

const mapStateToProps = (state: RootState):StandardNumberInputStateProps => ({ userPreferences: state[currentUserReducerName].userPreferences });

const decorate = compose<ComponentType<StandardNumberInputOwnProps>>(
    connect(mapStateToProps),
    withTranslation('common'),
);

export default decorate(StandardNumberInput);
