import React from 'react';
import { MdInfo } from 'react-icons/md';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { getDefaultLightDarkValues } from 'utils/theme-tools';
import StandardTooltip from 'components/common/atoms/StandardTooltip';
import TheIcon from 'components/common/atoms/TheIcon';

import { InfoIconTooltipProps } from './InfoIconTooltip.types';


function InfoIconTooltip({ infoTooltipText }: InfoIconTooltipProps) {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    return (
        <Box lineHeight={'14px'}>
            <StandardTooltip title={infoTooltipText}>
                <TheIcon Icon={MdInfo} colorScheme={colorScheme} />
            </StandardTooltip>
        </Box>
    );
}

export default InfoIconTooltip;
