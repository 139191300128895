import { Divider, Flex, Stack } from '@chakra-ui/react';
import React, { JSX } from 'react';
import { useColorMode } from '@chakra-ui/system';

import StandardButton from 'components/common/atoms/Button';

import { SidebarProps } from './Sidebar.types';
import { createUserDropdownItemsConfig } from '../UserDropdown/UserDropodown.helpers';
import UserProfile from '../UserProfile';
import AppLogoLink from '../AppLogo';


const Sidebar = ({
    t,
    enhancedCurrentLocation,
    fullName,
    firstName,
    lastName,
    dispatchClearCurrentUser,
    dispatchShowConfirmation,
    dispatchShowModal,
}: SidebarProps): JSX.Element => {
    const { toggleColorMode } = useColorMode();
    const currentPathname = enhancedCurrentLocation?.pathname;

    return (
        <Flex as="section" minH="100vh" bg="bg-canvas">
            <Flex
                flex="1"
                bg="bg-accent"
                color="on-accent"
                maxW={{
                    base: 'full',
                    sm: 'xs',
                }}
                py={{
                    base: '6',
                    sm: '8',
                }}
                px={{
                    base: '4',
                    sm: '6',
                }}
            >
                <Stack justify="space-between" spacing="1">
                    <Stack
                        spacing={{
                            base: '5',
                            sm: '6',
                        }}
                        shouldWrapChildren
                    >
                        <AppLogoLink />

                        <Divider />
                    </Stack>
                    <Stack spacing={{
                        base: '5',
                        sm: '6',
                    }}
                    >
                        <Stack spacing="1">
                            {createUserDropdownItemsConfig({
                                t,
                                dispatchClearCurrentUser,
                                dispatchShowModal,
                                toggleColorMode,
                            })
                                .map((menuItemConfiguration: any) => (
                                    <StandardButton
                                        key={menuItemConfiguration.id}
                                        label={menuItemConfiguration.label}
                                        id={menuItemConfiguration.id}
                                        dataTestId={menuItemConfiguration.dataTestId}
                                        icon={menuItemConfiguration.icon}
                                        variant={currentPathname === menuItemConfiguration?.pathname ? 'solid' : 'ghost'}
                                        onClick={() =>
                                            menuItemConfiguration.confirmation
                                                ? dispatchShowConfirmation({ ...menuItemConfiguration.confirmation })
                                                : menuItemConfiguration?.onClick()}
                                    />
                                ))}
                        </Stack>
                        <Divider />
                        <UserProfile name={fullName || `${firstName} ${lastName}`} />
                    </Stack>
                </Stack>
            </Flex>
        </Flex>
    );

};
export default Sidebar;
