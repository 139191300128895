import styled from '@emotion/styled';

export const PermissionsListWrapper = styled.div`
    container-type: inline-size;
`;

export const PermissionsList = styled.ul`
    columns: 1;
    break-after: avoid-column;
    
    @container (min-width: 500px) {
        columns: 2;
    }
`;

export const PermissionsListItem = styled.li`
    font-size: var(--chakra-fontSizes-md);
    font-weight: 500;
    text-align: start;
    margin-bottom: 0.5rem;
`;


export default {
    PermissionsListWrapper,
    PermissionsList,
    PermissionsListItem,
};
