import { Schema } from 'yup';
import { JSX, RefObject } from 'react';
import { SelectItemRenderer, SelectRenderer } from 'react-dropdown-select';
import { QueryParams } from './common';

export enum FormInputTypes {
    TEXT = 'TEXT',
    IBAN = 'IBAN',
    EMAIL = 'EMAIL',
    TEXT_MULTI_LINE = 'TEXT_MULTI_LINE',
    PASSWORD = 'PASSWORD',
    COLOR = 'COLOR',


    PIN = 'PIN',
    INTEGER = 'INTEGER',
    DECIMAL = 'DECIMAL',
    AMOUNT = 'AMOUNT',
    PHONE_NUMBER = 'PHONE_NUMBER',

    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',

    SWITCH = 'SWITCH',
    CHECK_BOX = 'CHECK_BOX',

    RADIO = 'RADIO',
    FANCY_RADIO = 'FANCY_RADIO',

    SELECT_SINGLE_VALUE = 'SELECT_SINGLE_VALUE',
    SELECT_MULTI_VALUES = 'SELECT_MULTI_VALUES',
    LAZY_AUTOCOMPLETE_SINGLE_VALUE = 'LAZY_AUTOCOMPLETE_SINGLE_VALUE',

    CUSTOM = 'CUSTOM',

}

export interface SelectOptionFactory {
    dataTestId?: string;
    value?: string | number;
    label?: string;
    variant?: string;
    hintText?: string;
}

export type SelectOptionExtended<T> = T & SelectOptionFactory;

export interface LazyAutocompleteFieldOptionalConfiguration<Item> {
    labelCreator?: (item: any) => string,
    valueKey?: string,
    reducerName?: string,
    defaultQueryParams?: QueryParams,
    listsByLocationStoreKeyName?: string,
    reduxSetter?: ({ queryParams, locationPathname }) => void,
    filterOptions?: (options: Item[]) => Item[]
    useFullObjectInOption?: boolean
    isSupportFetchOnSearch?: boolean,
}

export interface NumberInputFieldOptionalConfiguration {
    min?: number,
    max?: number,
    precision?: number,
    step?: number,
}

export interface SelectFieldOptionalConfiguration {
    dataTestId?: string,
    dropdownPosition?: 'auto' | 'top' | 'bottom', // defaults to "bottom".
    options?: SelectOptionFactory[]
    isMultiSelect?: boolean,
    useOptionValueOnly?: boolean
    customNoDataItem?: any
    searchBy?: string[]
    customItemRenderer?: ({ item, itemIndex, props, state, methods, dataTestId }: SelectItemRenderer<any> & {
        dataTestId?: string,
    }) => JSX.Element;
    customContentRenderer?: ({ props, state, methods, placeholder, ref }: SelectRenderer<any> & {
        placeholder?: string,
        ref?: RefObject<HTMLInputElement>,
        dataTestId?: string,
    }) => any;
}

export interface CommonFormFieldConfigItem {
    conditionalRender?: ({ context }) => boolean
    fieldType: FormInputTypes,

    isFieldArrayField?: boolean,
    name: string,
    id?: string,
    labelText?: string | JSX.Element,
    placeholderText?: string,
    hintText?: string,
    infoTooltipText?: string,
    errorText?: string,
    value?: string | number | object | any,
    dataTestId?: string,

    blockLabel?: boolean,
    isLoading?: boolean,
    children?: JSX.Element,
    inputLeftElement?: JSX.Element,
    inputRightElement?: JSX.Element,
    hasError?: boolean,
    optionalConfiguration?: LazyAutocompleteFieldOptionalConfiguration<any>
        & NumberInputFieldOptionalConfiguration
        & SelectFieldOptionalConfiguration
        & {

        isInvalid?: boolean,
        isReadOnly?: boolean,
        isRequired?: boolean,
        isDisabled?: boolean,
        isClearable?: boolean,
        isSearchable?: boolean,
        isHoneypot?: boolean,

        prefix?: string,

        size?: 'sm' | 'md' | 'lg',

        validationRules?: Schema,
        checkBoxLabel?: JSX.Element | string,
        checkBoxNonLabelContent?: JSX.Element | string,
    },
    onChange?: (eventOrValue: any) => void,
    onBlur?: (eventOrValue: any) => void,
    onFocus?: (eventOrValue: any) => void,
    onKeyDown?: (eventOrValue: any) => void,
    onSearch?: (searchValue: string) => void,
}
