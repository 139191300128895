import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Dispatch } from '@manigo/manigo-commons';

import withAccessControl from 'hoc/withAccessControl';
import { setQueryParams } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';
import { RootState } from 'store/rootReducer';
import { newConsentReducerName } from 'store/new-consent/reducer';
import { clearConsentDetails, fetchConsentDetails } from 'store/consent/actions';
import { consentDetailsPerLocationStoreKeyName, consentReducerName } from 'store/consent/reducer';
import { newConsentHasExpired } from 'store/new-consent/actions';

import NewConsentPage from './NewConsentPage.layout';
import { NewConsentPageDispatchProps, NewConsentPageStateProps } from './NewConsentPage.types';


const mapStateToProps = (state: RootState): NewConsentPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: consentReducerName,
        fieldName: consentDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    newConsentUuid: state[newConsentReducerName].newConsentUuid,
    showSuccessStep: state[newConsentReducerName].showSuccessStep,
});

const mapDispatchToProps = (dispatch: Dispatch): NewConsentPageDispatchProps => ({
    dispatchFetchConsentDetails: ({ queryParams, locationPathname }) => dispatch(fetchConsentDetails({ queryParams, locationPathname })),
    dispatchClearConsentDetails: ({ locationPathname }) => dispatch(clearConsentDetails({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: consentReducerName,
        fieldName: consentDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchNewConsentHasExpired: () => dispatch(newConsentHasExpired()),
});


const decorate = compose<ComponentType>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(NewConsentPage);
