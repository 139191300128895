import React from 'react';
import { MdUpdate } from 'react-icons/md';
import { ConsentListItem, ConsentScope, ConsentStatus } from '@manigo/manigo-domain-typings';
import { isValidIBAN, EnhancedLocation } from '@manigo/manigo-commons';
import { HStack } from '@chakra-ui/react';
import { TFunction } from 'i18next';

import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import { StandardValueType } from 'models/app/standardValues';
import { AccessControlContent } from 'models/domain/accessControl';
import { PN } from 'models/domain/permissions';
import { SimplifiedAccount } from 'models/domain/account';
import StandardValue from 'components/common/molecules/StandardValue';
import ConsentScopeTag from 'components/common/atoms/StandardTag/ConsentScopeTag';
import StandardLink from 'components/common/atoms/StandardLink';
import StandardButton from 'components/common/atoms/Button';
import AlertInfo from 'components/common/molecules/AlertInfo';
import ConsentStatusTag from 'components/common/atoms/StandardTag/ConsentStatusTag';
import AccountItem from 'components/common/organisms/AccountItem';
import { GridContainer, GridItemContainer, renderColumn } from 'components/common/organisms/StandardDataList/StandardDataListAccordion/StandardDataListAccordion.helpers';
import {
    StandardDataListAccordionContentItem,
    StandardDataListAccordionContentItemTitle,
    StandardDataListAccordionContentItemValue,
} from 'components/common/organisms/StandardDataList/StandardDataListAccordion/StandardDataListTableAccordion.styled';
import CurrencyRenderer from 'components/common/molecules/CurrencyRenderer';

import ConsentPermissionsRenderer from './Permissions';
import { ConsentsListPageDispatchProps } from './ConsentsListPage.types';
import Styled from './ConsentsListPage.styled';

export const createConsentsListTableColumns = ({
    t,
    accessControl,
    enhancedCurrentLocation,
    isLoadingRevokeConsent,
    dispatchRevokeConsent,
    dispatchShowConfirmation,
    accounts,
}: {
    t: TFunction,
    accounts?: SimplifiedAccount[],
    accessControl: AccessControlContent,
    enhancedCurrentLocation: EnhancedLocation,
    isLoadingRevokeConsent: boolean,
    dispatchRevokeConsent: ConsentsListPageDispatchProps['dispatchRevokeConsent'],
    dispatchShowConfirmation: ConsentsListPageDispatchProps['dispatchShowConfirmation'],
    }): TableColumnConfiguration<ConsentListItem> => ({
    idName: 'consentId',
    default: [{
        title: t('consent:consentFields.tppName.text'),
        align: TableColumnAlign.left,
        dataIndex: 'tppName',
        gridItemConfig: { colSpan: { base: 6, sm: 6, md: 2 } },
        render: (tppName: ConsentListItem['tppName']) => <StandardValue value={tppName} valueType={StandardValueType.TEXT} dataTestId="tpp-name" />,
    },
    {
        title: t('consent:consentFields.appName.text'),
        align: TableColumnAlign.left,
        dataIndex: 'appName',
        gridItemConfig: { colSpan: { base: 6, sm: 6, md: 2 } },
        render: (appName: ConsentListItem['appName']) => <StandardValue value={appName} valueType={StandardValueType.TEXT} />,
    },
    {
        title: t('consent:consentFields.scope.text'),
        align: TableColumnAlign.center,
        dataIndex: 'scope',
        gridItemConfig: { colSpan: { base: 3, sm: 2, md: 2 } },
        render: (scope: ConsentListItem['scope']) => <ConsentScopeTag value={scope} />,
    },
    {
        title: t('consent:consentFields.status.text'),
        align: TableColumnAlign.center,
        dataIndex: 'consentStatus',
        gridItemConfig: { colSpan: { base: 3, sm: 2, md: 2 } },
        render: (consentStatus: ConsentListItem['consentStatus']) => <ConsentStatusTag value={consentStatus} />,
    },
    {
        title: t('consent:consentFields.consentGranted.text'),
        align: TableColumnAlign.center,
        dataIndex: 'consentGranted',
        gridItemConfig: { colSpan: { base: 3, sm: 2, md: 2 } },
        render: (consentGranted: ConsentListItem['consentGranted']) => <StandardValue value={consentGranted} valueType={StandardValueType.DATE} showTime />,
    },
    {
        title: t('consent:consentFields.consentExpiry.text'),
        align: TableColumnAlign.center,
        dataIndex: 'consentExpiry',
        gridItemConfig: { colSpan: { base: 3, sm: 2, md: 2 } },
        render: (consentExpiry: ConsentListItem['consentExpiry']) => <StandardValue value={consentExpiry} valueType={StandardValueType.DATE} showTime />,
    }],
    expanded: [
        {
            title: t('consent:consentFields.appDescription.text'),
            align: TableColumnAlign.left,
            dataIndex: 'appDescription',
            gridItemConfig: { colSpan: { base: 12, md: 6, lg: 4 } },
            render: (appDescription: ConsentListItem['appDescription']) => <StandardValue value={appDescription} valueType={StandardValueType.TEXT} />,
        },
        {
            title: t('consent:consentFields.appWebsite.text'),
            align: TableColumnAlign.left,
            dataIndex: 'appWebsite',
            gridItemConfig: { colSpan: { base: 12, md: 6, lg: 4 } },
            render: (appWebsite: ConsentListItem['appWebsite']) => <StandardLink title={appWebsite} onClick={() => window.open(appWebsite)} />,
        },
        {
            title: t('consent:consentFields.tppWebsite.text'),
            align: TableColumnAlign.left,
            dataIndex: 'tppWebsite',
            gridItemConfig: { colSpan: { base: 12, md: 6, lg: 4 } },
            render: (tppWebsite: ConsentListItem['tppWebsite']) => <StandardLink title={tppWebsite} onClick={() => window.open(tppWebsite)} />,
        },
        {
            align: TableColumnAlign.left,
            dataIndex: 'paymentInformation',
            gridItemConfig: { colSpan: { base: 12 } },
            render: (paymentInformation: ConsentListItem['paymentInformation'], record) => {

                const paymentInfo = [
                    {
                        title: t('consent:consentFields.paymentInformation.creditorAccount.name.text'),
                        dataIndex: 'paymentInformation.creditorAccount.name',
                        gridItemConfig: { colSpan: { base: 12, md: 6 } },
                        align: TableColumnAlign.right,
                        render: () => <StandardValue value={`${record?.paymentInformation?.creditorAccount?.name}`} />,
                    },
                    {
                        title: t('consent:consentFields.paymentInformation.creditorAccount.identification.text'),
                        dataIndex: 'paymentInformation.creditorAccount.identification',
                        gridItemConfig: { colSpan: { base: 12, md: 6 } },
                        align: TableColumnAlign.right,
                        render: () => (
                            <StandardValue
                                value={`${record?.paymentInformation?.creditorAccount?.identification}`}
                                valueType={isValidIBAN(`${record?.paymentInformation?.creditorAccount?.identification}`)
                                    ? StandardValueType.IBAN
                                    : StandardValueType.TEXT}
                            />
                        ),
                    },
                    {
                        title: t('consent:consentFields.paymentInformation.instructedAmount.text'),
                        dataIndex: 'paymentInformation.instructedAmount.amount',
                        gridItemConfig: { colSpan: { base: 12, md: 6 } },
                        align: TableColumnAlign.right,
                        render: () => {
                            return (
                                <HStack justifyContent={'flex-end'}>
                                    <StandardValue value={record?.paymentInformation?.instructedAmount?.amount} valueType={StandardValueType.AMOUNT} />
                                    <CurrencyRenderer
                                        currencyIsoCode={`${record?.paymentInformation?.instructedAmount?.currency}`}
                                        showFullName={false}
                                        valueFirst
                                    />
                                </HStack>
                            );
                        },
                    },
                    {
                        title: t('consent:consentFields.paymentInformation.remittanceInformationReference.text'),
                        dataIndex: 'paymentInformation.remittanceInformation.reference',
                        gridItemConfig: { colSpan: { base: 12, md: 6 } },
                        align: TableColumnAlign.right,
                        render: () => <StandardValue value={`${record?.paymentInformation?.remittanceInformation?.reference}`} />,
                    },
                ];

                return record.scope === ConsentScope.payments ? (

                    <div>
                        <Styled.SubSectionTitle>
                            {`${t('consent:consentDetailsPage.sectionsHeadings.paymentInformation')}:`}
                        </Styled.SubSectionTitle>

                        <GridContainer>
                            {paymentInfo.map((column) => {
                                const { gridItemConfig } = column;
                                return (
                                    <GridItemContainer gridItemConfig={gridItemConfig} key={column.dataIndex}>
                                        <StandardDataListAccordionContentItem>
                                            {column.title && (
                                                <StandardDataListAccordionContentItemTitle>
                                                    {`${column.title}: `}
                                                </StandardDataListAccordionContentItemTitle>
                                            )}
                                            <StandardDataListAccordionContentItemValue $columnAlign={column.align}>
                                                {renderColumn(column, record)}
                                            </StandardDataListAccordionContentItemValue>
                                        </StandardDataListAccordionContentItem>
                                    </GridItemContainer>
                                );
                            })}
                        </GridContainer>

                    </div>
                ) : <></>;
            },
        },
        {
            align: TableColumnAlign.left,
            dataIndex: 'consentedAccounts',
            gridItemConfig: { colSpan: { base: 12 } },
            render: (consentedAccounts: ConsentListItem['consentedAccounts']) => consentedAccounts?.length > 0 ? (
                <div>
                    <Styled.SubSectionTitle>
                        {`${t('consent:consentFields.consentedAccounts.text')}:`}
                    </Styled.SubSectionTitle>

                    <Styled.ConsentedAccountsWrapper>
                        {consentedAccounts.map((accountUuid) => (
                            <AccountItem
                                key={accountUuid}
                                account={(accounts || []).find((account) => account.extAccountId === accountUuid)}
                                t={t}
                            />
                        ))}
                    </Styled.ConsentedAccountsWrapper>
                </div>
            ) : <></>,
        },
        {
            align: TableColumnAlign.left,
            dataIndex: 'permissions',
            gridItemConfig: { colSpan: { base: 12 } },
            render: (permissions: ConsentListItem['permissions'], record) =>
                record.scope === ConsentScope.accounts ? (
                    <div>
                        <Styled.SubSectionTitle>
                            {`${t('consent:consentFields.permissions.text')}:`}
                        </Styled.SubSectionTitle>
                        <ConsentPermissionsRenderer permissions={permissions} />
                    </div>
                ) : <></>,
        },
        {
            align: TableColumnAlign.right,
            dataIndex: 'consentId',
            gridItemConfig: { colSpan: { base: 12, md: 12, lg: 12 } },
            render: (consentId: ConsentListItem['consentId'], record: ConsentListItem) => (
                <Styled.RevokeConsentWrapper>
                    {
                        accessControl.hasPermission(PN.OPEN_BANKING.REVOKE_CONSENT_BY_ID)
                        && record.consentStatus === ConsentStatus.Authorised
                        && (
                            <StandardButton
                                id={'revoke-consent-button'}
                                label={t('buttons.revokeConsent.text')}
                                dataTestId={'revoke-consent-button'}
                                icon={MdUpdate}
                                isLoading={isLoadingRevokeConsent}
                                loadingText={t('buttons.revokeConsent.text')}
                                onClick={() => dispatchShowConfirmation({
                                    confirmationHeader: t('buttons.revokeConsent.confirmationHeader'),
                                    confirmationQuestion: t('buttons.revokeConsent.confirmationQuestion'),
                                    confirmationSubmitText: t('buttons.revokeConsent.confirmationSubmitText'),
                                    confirmationDecoration: <AlertInfo
                                        status={'info'}
                                        message={t('buttons.revokeConsent.confirmationInfo')}
                                    />,
                                    onConfirm: () => dispatchRevokeConsent({
                                        consentUuid: consentId,
                                        locationPathname: enhancedCurrentLocation.pathname,
                                    }),
                                })}
                            />
                        )
                    }
                </Styled.RevokeConsentWrapper>
            ),
        },
    ],
});
