import React from 'react';

import { useColorModeValue } from '@chakra-ui/react';
import { getChakraVarColorByChakraHookReturn, getDefaultLightDarkValues } from 'utils/theme-tools';
import Styled from './AnimatedCheckMark.styled';


function AnimatedCheckMark({ size = '200px' }) {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    return (
        <Styled.AnimatedCheckMarkWrapper $size={size} $colorScheme={getChakraVarColorByChakraHookReturn(colorScheme)}>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="26" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </Styled.AnimatedCheckMarkWrapper>
    );
}


export default AnimatedCheckMark;
