/**
 * Extending the Window interface to add a new method to the window object
 * @example window.applicationVersionData() - to get the application version data
 */
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export interface WindowModel extends Window {
    applicationVersionData: () => void;
    store?: ToolkitStore
}

export const browserWindow = window as unknown as WindowModel;
