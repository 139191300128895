import React from 'react';
import { Icon as ChakraIcon } from '@chakra-ui/react';

import { TheIconOwnProps } from './TheIcon.types';

function TheIcon({ Icon, colorScheme, boxSize }: TheIconOwnProps) {
    return (
        <ChakraIcon
            as={Icon as any}
            color={colorScheme}
            boxSize={boxSize}
            verticalAlign={'middle'}
        />
    );
}

export default TheIcon;
