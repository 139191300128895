import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import { Dispatch } from '@manigo/manigo-commons';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';
import { getStandardListData } from 'store/standardLists.helpers';
import { RootState } from 'store/rootReducer';
import { navigationReducerName } from 'store/navigation/reducer';
import { consentsListsPerLocationStoreKeyName, consentsReducerName } from 'store/consents/reducer';
import { listReducerName } from 'store/list/reducer';
import { clearConsentsList, fetchConsentsList } from 'store/consents/actions';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { markAsUpdatedList } from 'store/list/actions';
import { revokeConsent } from 'store/consent/actions';
import { consentReducerName } from 'store/consent/reducer';
import { showConfirmation } from 'store/alert/actions';
import { currentUserReducerName } from 'store/current-user/reducer';
import ConsentsListPage from './ConsentsListPage.layout';
import { ConsentsListPageDispatchProps, ConsentsListPageStateProps } from './ConsentsListPage.types';

const mapStateToProps = (state: RootState): ConsentsListPageStateProps => ({
    isLoadingRevokeConsent: state[consentReducerName].isLoadingRevokeConsent,
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<any>({
        state,
        reducerName: consentsReducerName,
        listsByLocationStoreKeyName: consentsListsPerLocationStoreKeyName,
    }),
    listWatchers: state[listReducerName].listWatchers,
    accounts: state[currentUserReducerName].accounts,
});

const mapDispatchToProps = (dispatch: Dispatch): ConsentsListPageDispatchProps => ({
    dispatchFetchBeneficiariesList: ({ queryParams, locationPathname }) => dispatch(fetchConsentsList({
        queryParams,
        locationPathname,
    })),
    dispatchClearBeneficiariesList: ({ locationPathname }) => dispatch(clearConsentsList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: consentsReducerName,
        fieldName: consentsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchMarkAsUpdatedList: ({ locationPathname }) => dispatch(markAsUpdatedList({
        locationPathname,
        entityReducerName: consentsReducerName,
    })),
    dispatchRevokeConsent: ({ consentUuid, locationPathname }) => dispatch(revokeConsent({ consentUuid, locationPathname })),
    dispatchShowConfirmation: (payload) => dispatch(showConfirmation(payload)),
});


const decorate = compose<ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withAccessControl,
    withTranslation('consent'),
);

export default decorate(ConsentsListPage);
