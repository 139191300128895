import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { setQueryParams } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';

import LazyAutocomplete from './LazyAutocomplete.layout';
import { LazyAutocompleteDispatchProps, LazyAutocompleteOwnProps, LazyAutocompleteStateProps } from './LazyAutocomplete.types';

const mapStateToProps = (state: RootState, ownProps): LazyAutocompleteStateProps => {
    const { listsByLocationStoreKeyName, reducerName } = ownProps.fieldConfiguration.optionalConfiguration;
    return {
        enhancedCurrentLocation: state[navigationReducerName].currentLocation,
        listData: getStandardListData({
            state,
            listsByLocationStoreKeyName,
            reducerName,
        }),
    };
};
const mapDispatchToProps = (dispatch, ownProps): LazyAutocompleteDispatchProps => {
    const { listsByLocationStoreKeyName, reducerName, reduxSetter } = ownProps.fieldConfiguration.optionalConfiguration;
    return {
        dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
            reducerName,
            fieldName: listsByLocationStoreKeyName,
            locationPathname,
            queryParams,
        })),
        dispatchSetListData: ({ queryParams, locationPathname }) => dispatch(reduxSetter({ queryParams, locationPathname })),
    };
};

const decorate = compose<ComponentType<LazyAutocompleteOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LazyAutocomplete);
