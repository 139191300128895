/* eslint-disable max-len */
import React from 'react';
import { WL_CONFIG } from 'config/environment';
import demoImages from 'assets/images/demoImages';

import Styled from './AppMaintenancePlaceholder.styled';


function AppMaintenancePlaceholder() {
    return (
        <Styled.MaintenancePlaceholder>
            <Styled.MaintenanceGears src={demoImages.maintenanceCogs} />

            <Styled.MaintenanceCopyWrapper>
                <h1>
                    {WL_CONFIG.brandShortName}
                    {' '}
                    {WL_CONFIG.applicationName}
                </h1>
                <h2>Coming soon</h2>
            </Styled.MaintenanceCopyWrapper>
        </Styled.MaintenancePlaceholder>
    );
}

export default AppMaintenancePlaceholder;
