import { Timestamp, NumericId, RawJwtToken } from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';
import { setSessionStorageObjectItem, calculateTimeToSilentTokenRefresh, jwtDecode } from '@manigo/manigo-commons';

import { UserDataFormToken } from 'models/domain/user';
import {
    UserPreferences,
    UserPreferencesDecimalSeparators,
    UserPreferencesThousandsSeparators,
    UserPreferencesTimeFormat,
    UserPreferencesDateFormat,
} from 'models/domain/userPreferencs';
import { ConfirmLoginResponsePayload } from 'models/domain/authorisation';
import { defaultLocale, userPreferencesKey } from 'config/config';


import {
    CLEAR_CURRENT_USER,
    EXTEND_TOKEN_VALIDITY_SUCCESS, SET_CURRENT_USER,
    SET_CURRENT_USER_SUCCESS,
} from './actions.types';
import SET_CURRENT_USER_VARIANT from './epics.helpers';

export const currentUserReducerName = 'currentUser';

export interface CurrentUserStateType {
    jwtToken?: RawJwtToken;
    accessTokenExpirationTimeStamp?: Timestamp;
    refreshTokenExpirationTimeStamp?: Timestamp;
    userData?: UserDataFormToken;
    permissions?: any;
    userPreferences: UserPreferences;

    timeToAccessTokenRefresh?: number;
    variant?: SET_CURRENT_USER_VARIANT,
    clientId?: NumericId,
    companyId?: NumericId,
    businessUserId?: NumericId,
    memberId?: NumericId,
    accounts?: ConfirmLoginResponsePayload['accounts'];
}

export const currenciesInitialState: CurrentUserStateType = {
    jwtToken: undefined,
    accessTokenExpirationTimeStamp: undefined,
    refreshTokenExpirationTimeStamp: undefined,
    permissions: undefined,
    userData: undefined,
    userPreferences: {
        timeFormat: UserPreferencesTimeFormat.HOURS_24,
        dateFormat: UserPreferencesDateFormat.YEAR_MONTH_DAY_DASH,
        language: defaultLocale,
        thousandsSeparator: UserPreferencesThousandsSeparators.COMMA,
        decimalSeparator: UserPreferencesDecimalSeparators.DOT,
    },
    timeToAccessTokenRefresh: undefined,
    variant: undefined,

    clientId: undefined,
    companyId: undefined,
    businessUserId: undefined,
    accounts: undefined,
};


const handleNewToken = (state: CurrentUserStateType, data) => {
    const {
        accessToken,
        expirationTimeOfAccessToken,
        expirationTimeOfRefreshToken,
        clientId,
        companyId,
        businessUserId,
        memberId,
        accounts,
    } = data;

    const decodedUserData: UserDataFormToken = jwtDecode(accessToken);
    state.jwtToken = accessToken;
    state.accessTokenExpirationTimeStamp = new Date(expirationTimeOfAccessToken).getTime();
    state.refreshTokenExpirationTimeStamp = new Date(expirationTimeOfRefreshToken).getTime();
    state.timeToAccessTokenRefresh = calculateTimeToSilentTokenRefresh(expirationTimeOfAccessToken);
    state.userData = decodedUserData;
    state.clientId = clientId;
    state.companyId = companyId;
    state.memberId = memberId;
    state.businessUserId = businessUserId;
    state.accounts = accounts;

    if (decodedUserData?.preferences) {
        state.userPreferences = decodedUserData.preferences;
    } else {
    // getSessionStorageObjectItem()
    }

    setSessionStorageObjectItem(userPreferencesKey, decodedUserData.preferences);
};

const createCurrentUserBuilderCases = (builder) => {
    builder
        .addCase(SET_CURRENT_USER, (state, action) => {
            state.variant = action.payload?.variant;
        })
        .addCase(SET_CURRENT_USER_SUCCESS, (state, action) => {
            handleNewToken(state, action.payload);
            state.permissions = action.payload.permissions;
        })
        .addCase(EXTEND_TOKEN_VALIDITY_SUCCESS, (state, action) => {
            handleNewToken(state, action.payload);
        })
        .addCase(CLEAR_CURRENT_USER, () => currenciesInitialState);

};

export default createReducer(currenciesInitialState, createCurrentUserBuilderCases);
