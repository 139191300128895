import styled from '@emotion/styled';

export const NewConsentLayout = styled.div`
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 2rem;
    height: 100%;

    form {
        display: grid;
        grid-template-rows: minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);
        height: 100%;
    }
`;

export const NewConsentFormContent = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 2rem;
    min-height: 100%;

    .accountsList {
        order: 2
    }

    .newConsentDetails {
        order: 1;

        .chakra-button {
            font-size: var(--chakra-fontSizes-xl);
        }
    }

    @media (min-width: 768px) {
        grid-template-rows: minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-column-gap: 2rem;
        grid-row-gap: 0;

        .accountsList {
            order: 1
        }

        .newConsentDetails {
            order: 2
        }
    }
`;


export const AccountsList = styled.div`
    @media (min-width: 768px) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
`;


export default {
    NewConsentLayout,
    AccountsList,
    NewConsentFormContent,
};
