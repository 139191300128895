import { KeyboardEvent } from 'react';
import { DigitsCodeInputDispatchProps } from './DigitsCodeInput.types';

export const onInputKeyDown = ({
    event,
    formItem,
    dispatchSetPasscodeDigit,
}: {
    event: KeyboardEvent,
    formItem: any,
    dispatchSetPasscodeDigit: DigitsCodeInputDispatchProps['dispatchSetPasscodeDigit']
}) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    const { key } = event;

    const isBackspace = key === 'Backspace';

    if (allowedKeys.includes(key)) {
        dispatchSetPasscodeDigit({ key: formItem.key, value: isBackspace ? '' : key });
        const targetElement = isBackspace ? formItem.prevRef?.current : formItem.nextRef?.current;

        setTimeout(() => {
            if (targetElement) targetElement.focus();
        });
    }
};
