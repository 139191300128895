import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import { localesReducerName } from 'store/locales/reducer';

import { authorisationReducerName } from 'store/authorisation/reducer';
import AuthorisedLayout from './AuthorisedLayout.layout';
import { AuthorisedLayoutOwnProps, AuthorisedLayoutStateProps } from './AuthorisedLayout.types';

const mapStateToProps = (state: RootState): AuthorisedLayoutStateProps => ({
    enhancedCurrentLocation: state.navigation.currentLocation,
    isLoadingLanguage: state[localesReducerName].isLoadingLanguage,
    isRefreshingSession: state[authorisationReducerName].isRefreshingSession,
});

const decorate = compose<ComponentType<AuthorisedLayoutOwnProps>>(
    connect(mapStateToProps),
);

export default decorate(AuthorisedLayout);
