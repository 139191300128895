import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { radioAnatomy } from '@chakra-ui/anatomy';

import { BASE_COLOR_DARK_NUMBER, BASE_COLOR_NUMBER, getChakraColor } from 'utils/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
    control: {
        _checked: {
            background: getChakraColor(BASE_COLOR_NUMBER),
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
            boxShadow: `0 0 0 1px ${getChakraColor(BASE_COLOR_NUMBER)}`,

            _hover: {
                background: getChakraColor(BASE_COLOR_NUMBER),
                borderColor: getChakraColor(BASE_COLOR_NUMBER),
                boxShadow: `0 0 0 1px ${getChakraColor(BASE_COLOR_NUMBER)}`,
            },
        },
        _dark: {
            _checked: {
                background: getChakraColor(BASE_COLOR_DARK_NUMBER),
                borderColor: getChakraColor(BASE_COLOR_DARK_NUMBER),
                boxShadow: `0 0 0 1px ${getChakraColor(BASE_COLOR_DARK_NUMBER)}`,
            },
        },
    },
});
const switchTheme = defineMultiStyleConfig({ baseStyle });
export default switchTheme;
