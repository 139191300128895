import React, { ComponentType, useRef } from 'react';
import {
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Modal,
} from '@chakra-ui/react';

import { ModalName } from 'models/app/modal';
import TosModal from 'components/common/templates/Modals/ToSModal';
import PrivacyPolicyModal from 'components/common/templates/Modals/PrivacyPolicyModal';
import FAQModal from 'components/common/templates/Modals/FAQModal';

import { ModalProps } from './ModalRoot.types';


const MODAL_COMPONENTS: { [key in ModalName]: ComponentType } = {
    [ModalName.TERMS_OF_SERVICE]: TosModal,
    [ModalName.PRIVACY_POLICY]: PrivacyPolicyModal,
    [ModalName.FAQ]: FAQModal,

};

function ModalRoot({ modalState, dispatchHideModal }: ModalProps) {
    const { modalProps, open, modalType } = modalState;
    const { title, modalSize, footer, id } = modalProps;
    const divRef = useRef<HTMLDivElement>(null);

    if (!modalType) return null;
    const SpecificModal = MODAL_COMPONENTS[modalType];

    return (
        <>
            <div ref={divRef} />
            <Modal
                isOpen={open}
                onClose={dispatchHideModal}
                motionPreset="slideInBottom"
                scrollBehavior="inside"
                size={modalSize}
                id={id}
                closeOnOverlayClick={false}
                portalProps={{ containerRef: divRef }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody pb={6}>
                        <SpecificModal {...modalProps} />
                    </ModalBody>

                    {footer && (
                        <ModalFooter>
                            {footer}
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}

export default ModalRoot;
