import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteConfiguration } from '@manigo/manigo-commons';
import { RedirectionToDefaultPathTypes } from 'models/app/navigation';

import { publicRoutes } from 'config/routes';
import DefaultRedirection from '../DefaultRedirection';

export function hasAccessRights({
    routeConfiguration,
    accessControl,
}) {
    const isPublic = publicRoutes.includes(routeConfiguration.path);

    const handlers = [
        // route-config-driven access control checks, if predicate is valid then redirection
        {
            predicate: () => isPublic === true && accessControl.isAuthorised,
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermission && !accessControl.hasPermission(routeConfiguration.hasPermission),
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermissions && !accessControl.hasPermissions(routeConfiguration.hasPermissions),
            handler: () => false,
        },
        {
            predicate: () => {
                if (!routeConfiguration.hasOneOfPermissions) {
                    return false;
                }

                const cumulativePermissions = routeConfiguration.hasOneOfPermissions.filter((permission) => Array.isArray(permission))?.[0];
                const standardPermissions = routeConfiguration.hasOneOfPermissions.filter((permission) => !Array.isArray(permission));
                if (cumulativePermissions) {
                    return !accessControl.hasOneOfPermissions(standardPermissions) && !accessControl.hasPermissions(cumulativePermissions);
                }
                return !accessControl.hasOneOfPermissions(routeConfiguration.hasOneOfPermissions);
            },
            handler: () => false,
        },
        {
            // default happy-path scenario, render component
            predicate: () => true,
            handler: () => true,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function renderApplicationContent({
    routesConfiguration,
    defaultRedirectionPath,
    location,
}) {

    return (
        <Routes location={location}>
            {routesConfiguration}

            <Route
                path="*"
                key="not-found"
                element={(
                    <DefaultRedirection
                        defaultRedirectionPath={defaultRedirectionPath}
                        navigationState={{
                            replace: true,
                            type: RedirectionToDefaultPathTypes.ROUTER_REDIRECTION_TO_DEFAULT_PATH,
                            trigger: 'not_found_redirection_to_default_path',
                            defaultRedirectionPath,
                        }}
                    />
                )}
            />
        </Routes>
    );
}

function renderRouteElement({ routeConfiguration }: { routeConfiguration: RouteConfiguration }) {
    const childrenRoutes = routeConfiguration.childrenRoutes || [];
    return (
        <Route
            path={routeConfiguration.path}
            key={routeConfiguration.path}
            element={routeConfiguration?.isIndex ? undefined : routeConfiguration.element}
        >
            {routeConfiguration?.isIndex
                ? (
                    <Route
                        index
                        key={`${routeConfiguration.path}-index`}
                        element={routeConfiguration.element}
                    />
                ) : <></>}

            {childrenRoutes.length > 0
                ? childrenRoutes.map((childRouteConfiguration) => renderRouteElement({ routeConfiguration: childRouteConfiguration }))
                : <></>}
        </Route>
    );
}

export function renderRoutes({ routesConfiguration }) {
    return routesConfiguration.map((routeConfiguration) => renderRouteElement({ routeConfiguration }));
}

