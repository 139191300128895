import React, { JSX, useCallback, useMemo } from 'react';

import { Button, IconButton } from '@chakra-ui/react';
import { LanguageDirection } from 'models/app/i18n';
import { useLanguageDirection } from 'hook/useLanguageDirection';
import StandardTooltip from 'components/common/atoms/StandardTooltip';

import { StandardLinkProps } from './StandardLink.types';


function StandardLink({
    title,
    path,
    dispatchRequestNavigation,
    navigationState,
    children,
    onClick,
    dataTestId,
    leftIcon,
    rightIcon,
    icon,
    size,
    buttonVariant,
    iconVariant = 'outline',
    showTooltip = true,
}: StandardLinkProps): JSX.Element {
    // TODO rewrite this
    // eslint-disable-next-line no-nested-ternary
    const handleOnclick = useCallback(() => (onClick && !path
        ? onClick()
        : path
            ? dispatchRequestNavigation({ locationPathname: path, meta: navigationState })
            : undefined
    ), [onClick, path, navigationState]);

    const languageDirection = useLanguageDirection();
    const reverseIcons = languageDirection === LanguageDirection.rtl;

    const theLink = useMemo(() => icon ? (
        <IconButton
            aria-label={title}
            size={size}
            icon={icon}
            data-test-id={dataTestId || 'standard-icon-link'}
            variant={iconVariant}
            onClick={handleOnclick}
        />
    ) : (
        <Button
            size={size}
            variant={buttonVariant || 'link'}
            type="button"
            leftIcon={reverseIcons ? rightIcon : leftIcon}
            rightIcon={reverseIcons ? leftIcon : rightIcon}
            data-test-id={dataTestId || 'standard-link'}
            onClick={handleOnclick}
            height="unset"
        >
            {children || title}
        </Button>
    ), [icon, handleOnclick]);


    return showTooltip
        ? (
            <StandardTooltip title={title}>
                {theLink}
            </StandardTooltip>
        ) : theLink;
}

export default StandardLink;
