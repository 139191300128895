import React from 'react';

import StandardButton from 'components/common/atoms/Button';
import Styled from './RefreshButton.styled';
import { RefreshButtonOwnProps } from './RefreshButton.types';


function RefreshButton({ onClick, dataTestId, label, t }: RefreshButtonOwnProps): JSX.Element {
    return (
        <Styled.RefreshButtonWrapper>
            <StandardButton
                variant={'outline'}
                id={'data-list-refresh-button'}
                dataTestId={'data-list-refresh-button' || dataTestId}
                size="xs"
                onClick={onClick}
                label={label || t('common:refreshList')}
            />
        </Styled.RefreshButtonWrapper>
    );
}


export default RefreshButton;
