import styled, { css } from 'styled-components';

const LoadingLocalesPlaceholder = styled.div<{ enableOverflowHidden?: boolean }>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: grid;
  align-content: center;
  justify-content: center;

  ${({ enableOverflowHidden = false }) => enableOverflowHidden && css`
    overflow: hidden;
    z-index: 10000;
  `};
`;


export default { LoadingLocalesPlaceholder };
