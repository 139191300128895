import { useSelector } from 'react-redux';
import { LanguageDirection } from 'models/app/i18n';
import { defaultLocale } from 'config/config';
import { RootState } from 'store/rootReducer';

import { localesReducerName } from 'store/locales/reducer';
import { getDirectionFromLocale } from '../utils/locales-tools';

export function useLanguageDirection(): LanguageDirection {
    const selectedLocale = useSelector((state: RootState) => state[localesReducerName].selectedLocale);
    return getDirectionFromLocale(selectedLocale || defaultLocale);
}
