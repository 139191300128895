import React from 'react';
import { MdEmail, MdKey, MdPhone } from 'react-icons/md';
import { emailRFC5322CompliantRegexp } from '@manigo/manigo-commons';
import * as yup from 'yup';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import { LoginType } from 'models/domain/authorisation';
import TheIcon from 'components/common/atoms/TheIcon';

export const createLoginFormLoginTypeConfiguration = ({ t }): CommonFormFieldConfigItem =>
    ({
        fieldType: FormInputTypes.RADIO,
        id: 'login-type',
        name: 'loginType',
        dataTestId: 'login-type-checkbox',
        blockLabel: t('unauthorised:formsElements.loginType.text'),
        infoTooltipText: t('unauthorised:formsElements.loginType.infoTooltipText'),
        optionalConfiguration: {
            options: [
                { value: LoginType.EMAIL, label: t('unauthorised:formsElements.loginType.values.email') },
                { value: LoginType.PHONE_NUMBER, label: t('unauthorised:formsElements.loginType.values.phone') },
            ],
        },
    });

export const createLoginFormDynamicInputsConfiguration = ({ t }): CommonFormFieldConfigItem[] => [
    {
        fieldType: FormInputTypes.EMAIL,
        id: 'email-input',
        name: 'login',
        dataTestId: 'email-input',
        labelText: t('unauthorised:formsElements.email.text'),
        placeholderText: t('unauthorised:formsElements.email.placeholder'),
        infoTooltipText: t('unauthorised:formsElements.email.infoTooltipText'),
        inputLeftElement: <TheIcon Icon={MdEmail} />,
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.PHONE_NUMBER,
        id: 'phone-number-input',
        name: 'login',
        dataTestId: 'phone-number-input',
        labelText: t('unauthorised:formsElements.phoneNumber.text'),
        placeholderText: t('unauthorised:formsElements.phoneNumber.placeholder'),
        infoTooltipText: t('unauthorised:formsElements.phoneNumber.infoTooltipText'),
        inputLeftElement: <TheIcon Icon={MdPhone} />,
        optionalConfiguration: { isRequired: true },
    },
];

export const createLoginFormPasswordRememberMeConfiguration = ({ t }): CommonFormFieldConfigItem[] => [
    {
        fieldType: FormInputTypes.PASSWORD,
        id: 'password',
        name: 'password',
        dataTestId: 'login-password-input',
        labelText: t('unauthorised:formsElements.password.text'),
        placeholderText: t('unauthorised:formsElements.password.placeholder'),
        inputLeftElement: <TheIcon Icon={MdKey} />,
        optionalConfiguration: { isRequired: true },
    },
];

export const createLoginFormValidationSchema = ({ t }) => {
    return yup.object().shape({
        loginType: yup.string().required(t('unauthorised:validationMsg.fieldMandatory')),

        login: yup.string()
            .when('loginType', {
                is: val => val === LoginType.EMAIL,
                then: () => yup.string()
                    .required(t('unauthorised:validationMsg.fieldMandatory'))
                    .matches(emailRFC5322CompliantRegexp, t('unauthorised:validationMsg.emailInvalidFormat'))
                    .max(100, t('unauthorised:validationMsg.maximumLength', { maxCount: 100 })),
                otherwise: () => yup.string()
                    .required(t('unauthorised:validationMsg.fieldMandatory'))
                    .max(16, t('unauthorised:validationMsg.maximumLength', { maxCount: 16 })),
            }),


        password: yup.string()
            .required(t('unauthorised:validationMsg.fieldMandatory'))
            .max(100, t('unauthorised:validationMsg.maximumLength', { maxCount: 100 }))
        ,
    }).required();
};


export default {
    createLoginFormLoginTypeConfiguration,
    createLoginFormDynamicInputsConfiguration,
    createLoginFormPasswordRememberMeConfiguration,
    createLoginFormValidationSchema,
};
