import React from 'react';
import { availableLocales, availableLanguagesLanguageNativeNames } from 'config/i18n';
import FlagAndValue from 'components/common/molecules/FlagAndValue';


export const createLanguageSelectorItemsConfig = ({ dispatchLoadLanguage, selectedLocale }) => availableLocales.map((locale) => ({
    id: locale,
    label: <FlagAndValue countryCode={locale.slice(-2)} showTooltip={false} text={availableLanguagesLanguageNativeNames[locale]} />,
    onClick: () => selectedLocale !== locale ? dispatchLoadLanguage(locale) : undefined,
}));


export default { createLanguageSelectorItemsConfig };
