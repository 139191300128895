import { ComponentType } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';
import { RootState } from 'store/rootReducer';
import { authorisationReducerName } from 'store/authorisation/reducer';
import { localesReducerName } from 'store/locales/reducer';

import ApplicationContent from './ApplicationContent.layout';
import { ApplicationContentStateProps } from './ApplicationContent.types';


const mapStateToProps = (state: RootState): ApplicationContentStateProps => ({
    applicationState: state.application.applicationState,
    enhancedCurrentLocation: state.navigation.currentLocation,
    isRefreshingSession: state[authorisationReducerName]?.isRefreshingSession,
    isI18nReady: state[localesReducerName].isI18nReady,
});


const decorate = compose<ComponentType>(
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(ApplicationContent);
