import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import AppLogoLink from './AppLogo.layout';

const decorate = compose<ComponentType>(
    withTranslation('common'),
);
export default decorate(AppLogoLink);
