import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { timestampDiffInSeconds } from '@manigo/manigo-commons';

import Styled from './AutoLogoutBanner.styled';
import { AutoLogoutBannerProps } from './AutoLogoutBanner.types';

function AutoLogoutBanner(props:AutoLogoutBannerProps) {
    const { t, dispatchClearCurrentUser } = props;
    const [counter, setCounter] = useState<number>();
    const deadline = Date.now() + 1000 * 60; // XXX 60s countdown

    function getTimeRemaining(endTime) {
        const total = endTime - Date.now();
        return timestampDiffInSeconds(total);// XXX Math.floor(total / 1000);
    }

    function updateClock(timeInterval) {
        const timeRemaining = getTimeRemaining(deadline);
        setCounter(timeRemaining);

        if (timeRemaining <= 0) {
            clearInterval(timeInterval);
            dispatchClearCurrentUser();
        }
    }

    useEffect(() => {
        const timeInterval = setInterval(updateClock, 250);
        updateClock(timeInterval); // run function once at first to avoid delay

        return () => {
            clearInterval(timeInterval);
        };
    }, []);


    return (
        <Styled.AutoLogoutBannerWrapper>
            <Styled.AutoLogoutBanner justify="center" align="center" direction="row">
                <Styled.WarningIcon />
                <Styled.Message>
                    <Trans
                        t={t}
                        i18nKey="autoLogoutBanner"
                        ns="common"
                        values={{ counter }}
                        components={{ strong: <Styled.Strong /> }}
                    />
                </Styled.Message>
            </Styled.AutoLogoutBanner>
        </Styled.AutoLogoutBannerWrapper>
    );
}

export default AutoLogoutBanner;
