import React, { forwardRef } from 'react';

import { Button, IconButton } from '@chakra-ui/react';
import { ButtonConfiguration } from 'models/app/common';

import TheIcon from '../TheIcon';
import StandardTooltip from '../StandardTooltip';

const StandardButton = forwardRef<HTMLButtonElement, ButtonConfiguration>(({
    id,
    label,
    children,
    icon,
    type,
    isDisabled,
    variant,
    isLoading,
    onClick,
    tooltipTitle,
    tooltipPlacement = 'bottom',
    dataTestId,
    isIconButton,
    size,
    ...props
}, ref) => {
    if (children) return <>{children}</>;
    const commonProps = {
        id,
        type,
        isDisabled,
        variant,
        onClick,
        'aria-label': id,
        size,
        ...(dataTestId ? { 'data-test-id': dataTestId } : {}),
        ...props,
    };
    const componentIcon = icon ? <TheIcon Icon={icon} /> : undefined;
    const button = isIconButton ? (
        <IconButton
            ref={ref}
            {...(icon ? { icon: componentIcon } : {})}
            {...commonProps}
        />
    ) : (
        <Button
            ref={ref}
            isLoading={isLoading}
            justifyContent={'start'}
            {...(icon ? { leftIcon: componentIcon } : {})}
            {...commonProps}
        >
            {label}
        </Button>
    );

    return (tooltipTitle
        ? (
            <StandardTooltip
                title={tooltipTitle ?? ''}
                placement={tooltipPlacement}
            >
                {button}
            </StandardTooltip>
        ) : button);

});

export default StandardButton;
StandardButton.displayName = 'StandardButton';
