import React from 'react';
import {
    Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Skeleton,
} from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';


import { getChakraVarColorWithEndpoint } from 'utils/theme-tools';
import { StandardAccordionProps } from './StandardAccordion.types';
import TheIcon from '../TheIcon';


function StandardAccordion({ itemsConfig, allowMultiple, allowToggle }: StandardAccordionProps): JSX.Element {
    return itemsConfig?.length > 0
        ? (
            <Accordion allowMultiple={allowMultiple} allowToggle={allowToggle}>
                {itemsConfig.map((itemConfig) => (
                    <AccordionItem key={itemConfig.id}>
                        {({ isExpanded }) => (
                            <Box>
                                <h2>
                                    <AccordionButton _expanded={{
                                        bg: getChakraVarColorWithEndpoint('500'),
                                        _dark: { bg: getChakraVarColorWithEndpoint('500') },
                                        color: 'white',
                                    }}
                                    >
                                        <Box as="span" flex="1" textAlign="left">
                                            {itemConfig.tabLabel}
                                        </Box>
                                        {isExpanded ? (
                                            <TheIcon Icon={MdExpandLess} />
                                        ) : (
                                            <TheIcon Icon={MdExpandMore} />
                                        )}
                                    </AccordionButton>
                                </h2>

                                <AccordionPanel pb={4}>
                                    {itemConfig.children}
                                </AccordionPanel>
                            </Box>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        ) : <Skeleton />;
}

export default StandardAccordion;
