import { defaultQueryParamsMeta, updateQueryParamsMeta } from 'models/app/applicationState';
import { extractBaseURLWithoutActiveTab } from 'components/common/molecules/StandardTabs/StandardTabs.helpers';
import { navigationReducerName } from './navigation/reducer';

export const standardEntityDetailsStateDataPlaceholder = {
    entityDetails: undefined,
    isLoadingDetails: false,
    // eslint-disable-next-line object-curly-newline
    queryParams: {},
    queryParamsMeta: defaultQueryParamsMeta,
};

export function getStandardEntityDetailsData({
    state,
    reducerName = '',
    fieldName = '',
    customLocation = undefined,
    baseURLSegmentsCount = 2,
}:{
    state: any,
    reducerName: string,
    fieldName: string,
    customLocation?: string,
    baseURLSegmentsCount?: number,
}) {
    const location = customLocation || state?.[navigationReducerName]?.currentLocation?.pathname;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(location, baseURLSegmentsCount);
    const entityDetailsData = state?.[reducerName]?.[fieldName][baseUrlWithoutTabsSlugs];
    return entityDetailsData;
}

export function updateStandardEntityDetails({ entitiesDetailsState = {}, action }) {
    const locationKey = action.payload?.locationPathname;
    const queryParamsMeta = updateQueryParamsMeta();
    return {
        ...entitiesDetailsState,
        [locationKey]: {
            ...entitiesDetailsState[locationKey],
            queryParams: action.payload?.queryParams,
            queryParamsMeta,
            isLoadingDetails: true,
        },
    };
}

export function updateStandardEntityDetailsOnSuccess({
    entitiesDetailsState = {},
    action,
}) {
    const queryParamsMeta = updateQueryParamsMeta();
    const locationKey = action.payload?.locationPathname;

    if (locationKey) {
        return {
            ...entitiesDetailsState,
            [locationKey]: {
                ...entitiesDetailsState[locationKey],
                queryParamsMeta,
                isLoadingDetails: false,
                entityDetails: {
                    ...entitiesDetailsState[locationKey]?.entityDetails,
                    ...action.payload.responsePayload,
                },
            },
        };
    }
    return entitiesDetailsState;
}

export function updateStandardEntityDetailsOnFailure({
    entitiesDetailsState = {},
    action,
}) {
    const queryParamsMeta = updateQueryParamsMeta(true);
    const locationKey = action.payload?.locationPathname;

    return {
        ...entitiesDetailsState,
        [locationKey]: {
            ...entitiesDetailsState[locationKey],
            queryParamsMeta,
            isLoadingDetails: false,
            entityDetails: null,
        },
    };
}


export function updateStandardEntityDetailsDataQueryParams({
    entitiesDetailsState = {},
    action,
}) {
    const locationPathname = action?.payload?.locationPathname;
    const listCurrentData = entitiesDetailsState[action?.payload?.locationPathname];


    if (locationPathname) {
        const currentData = listCurrentData || standardEntityDetailsStateDataPlaceholder;

        const clearedState = {
            ...entitiesDetailsState,
            [locationPathname]: {
                ...currentData,
                queryParams: {
                    ...currentData?.queryParams,
                    ...action.payload?.queryParams,
                },
            },
        };
        return clearedState;
    }

    return entitiesDetailsState;
}


export function clearStandardEntityDetails({
    entitiesDetailsState = {},
    entityDetailsLocationKeyName = '',
    action,
}) {
    const { [action.payload.locationPathname]: thisLocation, ...rest } = entitiesDetailsState[entityDetailsLocationKeyName];
    return { ...rest };
}

export function updateStandardEntityDetailsOnCleanup({
    entitiesDetailsState = {},
    action,
}) {
    const { locationPathname } = action.payload;
    const existingData = entitiesDetailsState[locationPathname];
    if (existingData) {
        const {
            [locationPathname]: theKeyToBeExcluded,
            ...rest
        }: any = entitiesDetailsState;

        return rest;
    }

    return entitiesDetailsState;
}


export default {
    standardEntityDetailsStateDataPlaceholder,
    // map state to props store getter
    getStandardEntityDetailsData,

    // standard 3 actions for API call + clear
    updateStandardEntityDetails,
    updateStandardEntityDetailsOnSuccess,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnCleanup,

    // standard query params
    updateStandardEntityDetailsDataQueryParams,
    clearStandardEntityDetails,
};
