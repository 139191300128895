import styled from 'styled-components';
import { Box } from '@chakra-ui/react';


const StandardAuthorisedPageContentWrapper = styled(Box)`
  margin: 0 auto;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;

export default { StandardAuthorisedPageContentWrapper };
