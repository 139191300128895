import { ApiError, LocationPathname, Action } from '@manigo/manigo-commons';

import { newConsentNotFound } from 'store/new-consent/actions';

import { fetchConsentDetailsFailure } from './actions';

export const handleGetConsentErrors = ({
    error,
    newConsentMode,
    locationPathname,
}: { error: ApiError, newConsentMode?: boolean, locationPathname: LocationPathname }): Action[] => {
    const handlers = [
        {
            predicate: () => newConsentMode && error.message === 'consent not found!',
            handler: () => [
                fetchConsentDetailsFailure({ locationPathname }),
                newConsentNotFound(),
            ],
        },
        {
            predicate: () => true,
            handler: () => [
                fetchConsentDetailsFailure({ locationPathname }),
            ],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
