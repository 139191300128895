import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { PartsStyleObject } from '@chakra-ui/system';

import {
    BASE_COLOR_DARK_NUMBER,
    BASE_COLOR_NUMBER,
    getChakraColor,
    getChakraVarColorWithEndpoint,
} from 'utils/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle: PartsStyleObject = definePartsStyle({
    field: {
        _focusVisible: {
            borderColor: 'unset',
            outline: 'none',
            border: `1px solid ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}`,
            boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)} !important`,
        },
        _focus: {
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
            boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}`,
        },
        _active: {
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
            boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}`,
        },
        _dark: {
            _focusVisible: {
                borderColor: 'unset',
                outline: 'none',
                border: `1px solid ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)}`,
                boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)}`,
            },
            _focus: {
                borderColor: getChakraColor(BASE_COLOR_DARK_NUMBER),
                boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)}`,
            },
            _active: {
                borderColor: getChakraColor(BASE_COLOR_DARK_NUMBER),
                boxShadow: `0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_DARK_NUMBER)}`,
            },
        },

    },
    props: { focusBorderColor: 'red.500' },
});

const inputTheme = defineMultiStyleConfig({ baseStyle });

export default inputTheme;
