import React from 'react';
import { withTranslation } from 'react-i18next';

import StandardTag from 'components/common/atoms/StandardTag';
import { ConsentScopeTagProps } from './ConsentScopeTag.types';
import { createConsentScopeTagHandlers } from './ConsentScopeTag.helpers';

function ConsentScopeTag({ value, t }: ConsentScopeTagProps) {
    const tagData = createConsentScopeTagHandlers({ value, t });

    return (
        <StandardTag
            color={tagData.color}
            value={tagData.text}
        />
    );
}

export default withTranslation('common')(ConsentScopeTag);
