import React, { JSX } from 'react';

import BrandAnimatedLoadingPlaceholder from 'components/common/atoms/BrandAnimatedLoadingPlaceholder';

import Styled from './AppLoadingPlaceholder.styled';
import { renderLoadingDetails } from './AppLoadingPlaceholder.helpers';
import { AppLoadingPlaceholderProps } from './AppLoadingPlaceholder.types';

function AppLoadingPlaceholder({
    currencies,
    countries,
    t,
    accessControl,
    isRefreshingSession,
    isI18nReady,

    asOverlay,
}: AppLoadingPlaceholderProps): JSX.Element {
    return (
        <Styled.AppLoadingPlaceholder data-test-id="app-loading-placeholder" $asOverlay={asOverlay}>
            <BrandAnimatedLoadingPlaceholder />

            {isI18nReady && renderLoadingDetails({
                currencies,
                countries,
                accessControl,
                isRefreshingSession,
                t,
            })}
        </Styled.AppLoadingPlaceholder>
    );
}

export default AppLoadingPlaceholder;

