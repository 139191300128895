import React, { JSX } from 'react';

import AppBlurOverlay from 'components/common/atoms/AppBlurOverlay';
import BrandAnimatedLoadingPlaceholder from 'components/common/atoms/BrandAnimatedLoadingPlaceholder';

import { LoadingLocalesPlaceholderProps } from './LoadingLocalesPlaceholder.types';
import Styled from './LoadingLocalesPlaceholder.styled';

function LoadingLocalesPlaceholder({
    children,
    isLoadingLanguage,
    isLoadingNamespaces,
}: LoadingLocalesPlaceholderProps) : JSX.Element {

    return (
        <>
            {(isLoadingLanguage || isLoadingNamespaces) && (
                <Styled.LoadingLocalesPlaceholder data-test-id="app-locales-loading-placeholder">
                    <BrandAnimatedLoadingPlaceholder />
                </Styled.LoadingLocalesPlaceholder>
            )}


            <AppBlurOverlay activeBlur={isLoadingLanguage || isLoadingNamespaces}>
                {children}
            </AppBlurOverlay>
        </>

    );
}


export default LoadingLocalesPlaceholder;
