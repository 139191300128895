import { combineReducers } from '@reduxjs/toolkit';


import alertReducer, { alertReducerName } from 'store/alert/reducer';
import applicationReducer, { applicationReducerName } from 'store/application/reducer';
import authorisationReducer, { authorisationReducerName } from 'store/authorisation/reducer';
import countriesReducer, { countriesReducerName } from 'store/countries/reducer';
import currencyReducer, { currenciesReducerName } from 'store/currencies/reducer';
import currentUserReducer, { currentUserReducerName } from 'store/current-user/reducer';
import localesReducer, { localesReducerName } from 'store/locales/reducer';
import modalReducer, { modalReducerName } from 'store/modal/reducer';
import navigationReducer, { navigationReducerName } from 'store/navigation/reducer';
import consentReducer, { consentReducerName } from 'store/consent/reducer';
import consentsReducer, { consentsReducerName } from 'store/consents/reducer';
import newConsentReducer, { newConsentReducerName } from 'store/new-consent/reducer';
import listReducer, { listReducerName } from 'store/list/reducer';

const rootReducer = combineReducers({
    // XXX very, very important reducers
    [applicationReducerName]: applicationReducer,
    [currentUserReducerName]: currentUserReducer,
    [navigationReducerName]: navigationReducer,
    [authorisationReducerName]: authorisationReducer,

    // screen/entity/controller specific reducers
    [alertReducerName]: alertReducer,

    [countriesReducerName]: countriesReducer,
    [currenciesReducerName]: currencyReducer,
    [consentReducerName]: consentReducer,
    [consentsReducerName]: consentsReducer,

    [listReducerName]: listReducer,
    [localesReducerName]: localesReducer,
    [modalReducerName]: modalReducer,
    [newConsentReducerName]: newConsentReducer,
});


export type RootState = ReturnType<typeof rootReducer>;


export { rootReducer };
