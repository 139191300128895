import React, { useMemo } from 'react';

import DigitInput from './DigitInput';
import Styled from './DigitsCodeInput.styled';
import { DigitsCodeInputProps, FieldConfig } from './DigitsCodeInput.types';

function DigitsCodeInput({
    loginConfirmationValues,
    references,
    dispatchSetPasscodeDigit,
    dispatchClearAllPasscodeDigits,
    loginConfirmFormError,
}: DigitsCodeInputProps) {
    const createFieldKey = (counter) => `digit${counter}Value`;
    const createDataTestId = (counter) => `passcode-input-${counter}`;
    const fieldsConfigs: FieldConfig[] = useMemo(
        () => ([
            {
                key: createFieldKey(1),
                dataTestId: createDataTestId(1),
                ownRef: references.refInputDigit1,
                prevRef: references.refInputDigit1,
                nextRef: references.refInputDigit2,
                autoFocus: true,
            },
            {
                key: createFieldKey(2),
                dataTestId: createDataTestId(2),
                ownRef: references.refInputDigit2,
                prevRef: references.refInputDigit1,
                nextRef: references.refInputDigit3,
            },
            {
                key: createFieldKey(3),
                dataTestId: createDataTestId(3),
                ownRef: references.refInputDigit3,
                prevRef: references.refInputDigit2,
                nextRef: references.refInputDigit4,
            },
            {
                key: createFieldKey(4),
                dataTestId: createDataTestId(4),
                ownRef: references.refInputDigit4,
                prevRef: references.refInputDigit3,
                nextRef: references.refInputDigit5,
            },
            {
                key: createFieldKey(5),
                dataTestId: createDataTestId(5),
                ownRef: references.refInputDigit5,
                prevRef: references.refInputDigit4,
                nextRef: references.refInputDigit6,
            },
            {
                key: createFieldKey(6),
                dataTestId: createDataTestId(6),
                ownRef: references.refInputDigit6,
                prevRef: references.refInputDigit5,
                nextRef: references.refButtonSubmit,
            },
        ]),
        [references],
    );

    return (
        <Styled.DigitsCodeInputWrapper>
            {fieldsConfigs.map((fieldConfig) => (
                <DigitInput
                    key={fieldConfig.key}
                    value={loginConfirmationValues[fieldConfig.key]}
                    hasError={loginConfirmFormError}
                    formItem={fieldConfig}
                    dispatchSetPasscodeDigit={dispatchSetPasscodeDigit}
                    dispatchClearAllPasscodeDigits={dispatchClearAllPasscodeDigits}
                    onPaste={(event) => {
                        const value = event.clipboardData.getData('text');
                        // validate pasted value
                        if (/^\d{6}$/.test(value)) {
                            value.split('').forEach((digit, index) => {
                                dispatchSetPasscodeDigit({ key: createFieldKey(index + 1), value: digit });
                                setTimeout(() => {
                                    if (references.refButtonSubmit?.current) references.refButtonSubmit.current.focus();
                                });
                            });
                        }
                    }}
                />
            ))}
        </Styled.DigitsCodeInputWrapper>
    );
}

export default DigitsCodeInput;
