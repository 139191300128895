import { ComponentSingleStyleConfig, defineStyleConfig } from '@chakra-ui/react';
import { BASE_COLOR_NUMBER, getChakraColor, getChakraVarColorWithEndpoint } from 'utils/theme-tools';

const CheckBoxStyle: ComponentSingleStyleConfig = defineStyleConfig({
    baseStyle: {
        _focusVisible: {
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
            boxShadow: `0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}`,
        },
        _focus: {
            boxShadow: 'none',
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
        },
        _active: {
            boxShadow: 'none',
            borderColor: getChakraColor(BASE_COLOR_NUMBER),
        },
    },
});

export default CheckBoxStyle;
