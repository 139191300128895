import React, { JSX } from 'react';
import { Skeleton } from '@chakra-ui/react';

import { FormInputTypes } from 'models/app/formContols';
import { SortDirections } from 'models/app/applicationState';
import StandardSelect from 'components/common/molecules/StandardSelect';

import Styled from '../StandardFilters.styled';


function SorterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    isListReady,
    t,
}): JSX.Element {

    return filtersConfiguration?.sortByOptions && filtersConfiguration?.sortByOptions?.length > 0
        ? (
            <>
                {isListReady
                    ? (
                        <>
                            <Styled.FilterWrapper>
                                <Styled.FilterLabel>
                                    {t('common:appUi.sortingOptions.sortBy.text')}
                                </Styled.FilterLabel>
                                <StandardSelect
                                    fieldConfiguration={{
                                        name: 'sortBy',
                                        dataTestId: 'sort-by-select',
                                        fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                        labelText: '',
                                        value: queryParams.sortBy,
                                        onChange: (value) => handleQueryParamsChange({
                                            fieldName: 'sortBy',
                                            value,
                                        }),
                                        optionalConfiguration: { options: filtersConfiguration?.sortByOptions },
                                    }}
                                />
                            </Styled.FilterWrapper>

                            <Styled.FilterWrapper>
                                <Styled.FilterLabel>
                                    {t('common:appUi.sortingOptions.sortOrder.text')}
                                </Styled.FilterLabel>
                                <StandardSelect
                                    fieldConfiguration={{
                                        name: 'sortOrder',
                                        dataTestId: 'sort-order-select',
                                        fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                        labelText: '',
                                        value: queryParams.sortOrder,
                                        onChange: (value) => handleQueryParamsChange({
                                            fieldName: 'sortOrder',
                                            value,
                                        }),
                                        optionalConfiguration: {
                                            options: [
                                                {
                                                    value: SortDirections.ASCEND,
                                                    label: t('common:appUi.sortingOptions.sortOrder.values.ascending'),
                                                },
                                                {
                                                    value: SortDirections.DESCEND,
                                                    label: t('common:appUi.sortingOptions.sortOrder.values.descending'),
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </Styled.FilterWrapper>
                        </>
                    )
                    : (
                        <>
                            <Styled.FilterWrapper>
                                <Skeleton height="50px" width="100%" rounded="md" />
                            </Styled.FilterWrapper>
                            <Styled.FilterWrapper>
                                <Skeleton height="50px" width="100%" rounded="md" />
                            </Styled.FilterWrapper>
                        </>
                    )}
            </>
        ) : <></>;

}

export default SorterFields;
