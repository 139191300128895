import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import { StandardFiltersOwnProps } from './StandardFilters.types';
import StandardFilters from './StandardFilters.layout';

const decorate = compose<ComponentType<StandardFiltersOwnProps>>(
    withTranslation('common'),
);

export default decorate(StandardFilters);
