import { isArray, isObject } from '@manigo/manigo-commons';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useEffectOnce from 'hook/useEffectOnce';
import StandardSelect from 'components/common/molecules/StandardSelect';

import { LazyAutocompleteProps } from './LazyAutocomplete.types';


function LazyAutocomplete({
    enhancedCurrentLocation,
    fieldConfiguration,
    listData,
    dispatchSetQueryParams,
    dispatchSetListData,
    t,
}: LazyAutocompleteProps) {

    const formContext = useFormContext();
    const watch = formContext?.watch;
    const setValue = formContext?.setValue;
    const { name, optionalConfiguration } = fieldConfiguration;

    const useOptionValueOnly = optionalConfiguration?.useOptionValueOnly;
    const key = optionalConfiguration?.valueKey || 'id';
    const isMultiSelect = optionalConfiguration?.isMultiSelect;

    const watchedValue = watch ? watch(fieldConfiguration.name) : null;

    const fetchData = (newQueryParams?: any) => {
        if (enhancedCurrentLocation?.pathname && !listData.isLoadingList) {
            const queryParams = {
                ...listData?.queryParams,
                ...(isObject(newQueryParams) ? newQueryParams : {}),
                ...(listData?.queryParams?.limit ? { limit: 100 } : { limit: 100 }),
                ...(optionalConfiguration?.defaultQueryParams || {}),
            };
            dispatchSetQueryParams({ queryParams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchSetListData({ queryParams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    useEffect(() => {
        fetchData();
    },
    []);

    // After fetch extend value with full object
    useEffectOnce(() => {
        const findValueFromList = (value) => listData.items?.find((item) => item?.[key] === value);
        const commonSetValueConfig = {
            shouldValidate: false,
            shouldDirty: false,
            shouldTouch: false,
        };

        if (isMultiSelect && watchedValue?.length) {
            setValue(name, watchedValue.map((item) => ([
                ...findValueFromList(item?.value),
                ...item,
            ]), commonSetValueConfig));
        } else if (watchedValue?.value) {
            setValue(name, {
                ...findValueFromList(watchedValue.value),
                ...watchedValue,
            }, commonSetValueConfig);
        }
    },
    watchedValue && !useOptionValueOnly && formContext,
    [watchedValue]);


    // TODO: add debounce and throttle
    // TODO: after react select has onSearch and onBlur, amend the needed changes
    const onSearch = (searchTerm: string) => {
        if (listData?.queryParams?.search !== searchTerm && fieldConfiguration.optionalConfiguration?.isSupportFetchOnSearch) fetchData({ search: searchTerm });
    };

    const isLoadingList = useMemo(() => listData?.isLoadingList, [listData?.isLoadingList]);

    const optionsList = useMemo(() => {
        if (!isArray(listData?.items) || !listData?.items) return [];

        const filterOptions = fieldConfiguration.optionalConfiguration?.filterOptions;

        // listData.items is readonly that's why for manipulating created new array
        const options = filterOptions ? filterOptions([...listData.items]) : listData?.items;
        return options.map((item) => ({
            label: fieldConfiguration.optionalConfiguration?.labelCreator
                ? fieldConfiguration.optionalConfiguration.labelCreator(item)
                : 'Missing option label',
            value: fieldConfiguration.optionalConfiguration?.valueKey
                ? item?.[fieldConfiguration.optionalConfiguration?.valueKey]
                : item.id,
            ...(fieldConfiguration.optionalConfiguration?.useFullObjectInOption ? { ...item } : {}),

        }));
    },
    [listData?.items?.length, fieldConfiguration]);


    const amendedFieldConfiguration = useMemo(() => {
        return {
            ...fieldConfiguration,
            isLoading: isLoadingList,
            disabled: isLoadingList,
            placeholderText: isLoadingList ? t('common:standardSelect.loading') : fieldConfiguration.placeholderText,
            optionalConfiguration: {
                ...fieldConfiguration.optionalConfiguration,
                options: optionsList,
            },
            onSearch,
        };
    },
    [fieldConfiguration, optionsList, isLoadingList]);
    return (
        <StandardSelect
            useOptionValueOnly={amendedFieldConfiguration?.optionalConfiguration?.useOptionValueOnly}
            fieldConfiguration={amendedFieldConfiguration}
        />
    );
}

export default LazyAutocomplete;
