import React, { useRef } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    HStack,
} from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';

import { LanguageDirection } from 'models/app/i18n';
import { useLanguageDirection } from 'hook/useLanguageDirection';
import TheIcon from 'components/common/atoms/TheIcon';

import { AlertProps } from './Alert.types';

function Alert({
    t,
    showConfirmation,
    dispatchHide,
    confirmationConfig: {
        confirmationHeader,
        confirmationQuestion,
        confirmationSubmitText,
        confirmationDecoration,
        onConfirm,
    },
}: AlertProps) {
    const languageDirection = useLanguageDirection();
    const reverseIcons = languageDirection === LanguageDirection.rtl;
    const cancelRef: React.MutableRefObject<null> = useRef(null);

    const handleConfirmation = () => {
        if (onConfirm) onConfirm();
        dispatchHide();
    };

    return (
        <AlertDialog
            isOpen={showConfirmation}
            leastDestructiveRef={cancelRef}
            onClose={dispatchHide}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {confirmationHeader}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {confirmationDecoration && (
                            <Box marginBottom={4}>
                                {confirmationDecoration}
                            </Box>
                        )}
                        {confirmationQuestion}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <HStack justify={'flex-end'}>
                            <Button ref={cancelRef} variant="outline" onClick={dispatchHide}>
                                {t('common:buttons.cancel.text')}
                            </Button>
                            <Button
                                rightIcon={reverseIcons ? <TheIcon Icon={MdSend} /> : undefined}
                                leftIcon={reverseIcons ? undefined : <TheIcon Icon={MdSend} />}
                                onClick={handleConfirmation}
                            >
                                {confirmationSubmitText || t('common:yes')}
                            </Button>
                        </HStack>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );

}

export default Alert;
