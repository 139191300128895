import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

//  TODO RWD styles
const FilterOuterWrapper = styled.div`
  position: relative;
  z-index: 1000;
  box-shadow: var(--chakra-shadows-md);
  margin-bottom: 10px;
`;

const FilterInnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const Col = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, auto);
  grid-column-gap: 10px;
  align-items: center;
  flex: 1;
`;

const FilterLabel = styled.span`
  white-space: nowrap;
`;


export default {
    FilterOuterWrapper,
    FilterInnerWrapper,
    Col,
    FilterWrapper,
    FilterLabel,
};
