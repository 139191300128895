import React, { JSX, useMemo } from 'react';
import { Spinner, Box, Heading } from '@chakra-ui/react';
import StandardTranslationWithMarkup from 'components/common/atoms/StandardTranslationWithMarkup';

import { TermsOfServiceProps } from './TermsOfService.types';

function TermsOfService({ t, tReady }: TermsOfServiceProps): JSX.Element {

    const sections = useMemo(
        () => {
            return tReady
                ? t('termsOfService:sections', { returnObjects: true }) as []
                : undefined;
        },
        [t, tReady],
    );

    return (
        <Box>
            {sections && sections?.length > 0 ? (
                <>
                    <div>
                        <Heading size="2xl" textAlign="center">{t('termsOfService:screenTitle.heading1')}</Heading>
                        <Heading size="xl" textAlign="center">{t('termsOfService:screenTitle.heading2')}</Heading>
                    </div>

                    {sections.map((section, index) => (
                        <Box key={index} pb={'20px'}>
                            <Heading pb={'10px'}>{t(`termsOfService:sections.${index}.heading`)}</Heading>
                            <StandardTranslationWithMarkup
                                translationKey={`sections.${index}.content`}
                                namespace="termsOfService"
                                values={{}}
                                t={t}
                            />
                        </Box>
                    ))}
                </>
            ) : <Spinner />}
        </Box>
    );
}


export default TermsOfService;
