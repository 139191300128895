import { ComponentSingleStyleConfig, defineStyleConfig } from '@chakra-ui/react';

const ModalStyle:ComponentSingleStyleConfig = defineStyleConfig({
    sizes: {
        xl: { dialog: { maxW: '6xl' } },
        xxl: { dialog: { maxW: '8xl' } },
        full: { dialog: { maxW: 'full' } },
    },
});
export default ModalStyle;
