import styled, { css } from 'styled-components';

const AppWrapper = styled.div<{ $enableOverflowHidden?: boolean }>`
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;


  ${({ $enableOverflowHidden = false }) => $enableOverflowHidden && css`
    overflow: hidden;
    z-index: 10000;
  `};
`;


export default { AppWrapper };
